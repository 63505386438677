import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import NotFound from './NotFound';
import Home from './User';
import ProductManagement from './Product';
import CategoryManagement from './Category';
import PromotionManagement from './Promotion';
import BannerManagement from './Banner';
import ResetPassword from './ResetPassword';

const Layout = lazy(() => import('./Layout'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: '*',
        element: <NotFound/>
      },
      {
        path: '/home',
        element: <Home/>
      },
      {
        path: '/product',
        element: <ProductManagement/>
      },
      {
        path: '/category',
        element: <CategoryManagement/>
      },
      {
        path: '/promotion',
        element: <PromotionManagement/>
      },
      {
        path: '/banner',
        element: <BannerManagement/>
      },
      {
        path: '/reset-password',
        element: <ResetPassword />
      },
    ]
  }
];
export default routes;
