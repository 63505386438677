import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField, Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Address, USER_TYPE_DICT, USER_TYPE_OPTIONS, UserCreateRequest } from '../model';
import moment from 'moment';
import {
  AppConfig,
  createUser,
  getChildConfigsByTypeAndCode,
  updateUser
} from '../../../service/Common';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import { isAuthAdmin } from '../../../service/Auth';
import LoadingBackdrop from '../../../components/global/LoadingBackdrop';
import _ from 'lodash';

interface Props {
  open: boolean;
  detail?: Partial<UserCreateRequest>;
  onClose: () => void;
  needRetrigger: () => void;
}

const CreateOrUpdateUserDialog = (props: Props) => {
  const authProfile = useSelector(getProfile);
  const isAdmin = isAuthAdmin(authProfile);
  const [currentForm, setCurrentForm] = useState<Partial<UserCreateRequest>>({});
  const [userAddress, setUserAddress] = useState<Partial<Address>>({});
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [provinceList, setProvinceList] = useState<AppConfig[]>([]);
  const [districtList, setDistrictList] = useState<AppConfig[]>([]);
  const [wardList, setWardList] = useState<AppConfig[]>([]);

  useEffect(() => {
    getChildConfigsByTypeAndCode('address', '0001')
    .then((res) => {
      setProvinceList(res);
    }).catch((e) => {
      console.error(e);
      setProvinceList([]);
    });
  }, []);

  useEffect(() => {
    if (!userAddress.provinceCode) {
      setDistrictList([]);
      return;
    }
    getChildConfigsByTypeAndCode('address', userAddress.provinceCode)
    .then((res) => {
      setDistrictList(res);
    }).catch((e) => {
      console.error(e);
      setDistrictList([]);
    });
  }, [userAddress.provinceCode]);

  useEffect(() => {
    if (!userAddress.provinceCode || !userAddress.districtCode) {
      setWardList([]);
      return;
    }
    getChildConfigsByTypeAndCode('address', userAddress.districtCode)
    .then((res) => {
      setWardList(res);
    }).catch((e) => {
      console.error(e);
      setWardList([]);
    });
  }, [userAddress.provinceCode, userAddress.districtCode]);

  useEffect(() => {
    if (props.detail) {
      setCurrentForm(props.detail);
      setUserAddress({ ..._.get(props.detail, 'address', {}) });
    } else {
      setCurrentForm({});
      setUserAddress({});
    }
  }, [props.detail]);
  const handleUpdate = (key: string, value: any) => {
    setCurrentForm((cur) => ({ ...cur, [key]: value }));
  };

  const updateUserAddress = (key: string, value: string) => {
    setUserAddress((cur) => ({ ...cur, [key]: value }));

  }
  const handleClose = () => {
    props.onClose();
    setCurrentForm({});
    setIsDirty(false);
  }

  const isValidUserName = (userType: string | undefined, text: string | undefined) => {
    if (!text) {
      return false;
    }
    if (!userType || userType === USER_TYPE_DICT.CUSTOMER.value || userType === USER_TYPE_DICT.AGENCY.value) {
      // only contain digit
      return /^[0-9]*$/.test(text);
    }
    return /^\S*$/.test(text);
  }

  function isVietnamesePhoneNumber(number?: string) {
    if (!number) {
      return false;
    }
    return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(number);
  }

  const isInvalidForm = () => {
    return !isValidUserName(currentForm.userType, currentForm.userName)
        || _.isEmpty(currentForm.userName)
        || (_.isEmpty(currentForm.password) && !currentForm.id)
        || !isVietnamesePhoneNumber(currentForm.phoneNumber)  ;
  }

  const handleCreateOrUpdate = useCallback(() => {
    setIsDirty(true);
    if (isInvalidForm()) {
      enqueueSnackbar('Thiếu trường thông tin bắt buộc', { variant: 'warning' });
      return;
    }
    setLoading(true);
    const submitForm = { ...currentForm, address: { ...userAddress } };
    if (!submitForm.id) {
      createUser(submitForm)
      .then(() => {
        enqueueSnackbar('Tạo tài khoản thành công', { variant: 'success' });
        handleClose();
        props.needRetrigger();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Tạo tài khoản thất bại', { variant: 'error' });
      }).finally(() => setLoading(false));
    } else {
      updateUser(submitForm.id, submitForm)
      .then(() => {
        enqueueSnackbar('Cập nhật tài khoản thành công', { variant: 'success' });
        handleClose();
        props.needRetrigger();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Cập nhật tài khoản thất bại', { variant: 'error' });
      }).finally(() => setLoading(false));
    }
  }, [currentForm, userAddress]);

  return (
      <Dialog
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          maxWidth="xl"
      >
        <DialogTitle
            id="scroll-dialog-title">
          <Typography sx={{ fontWeight: 700, fontSize: 20, flexGrow: 1 }}>
            {`${props.detail?.id ? 'Cập nhật' : 'Tạo mới'} người dùng`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <LoadingBackdrop open={isLoading}/>
          <Box style={{ margin: 20 }}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('name', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Tên"
                    name="name"
                    value={currentForm.name || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('phoneNumber', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="phoneNumber"
                    label="Số điện thoại"
                    name="phoneNumber"
                    required
                    value={currentForm.phoneNumber || ''}
                    error={(isDirty && !currentForm.phoneNumber) || Boolean(currentForm.phoneNumber && !isVietnamesePhoneNumber(currentForm.phoneNumber))}
                    helperText={isDirty && !currentForm.phoneNumber ?
                      'Không được để trống' : Boolean(currentForm.phoneNumber && !isVietnamesePhoneNumber(currentForm.phoneNumber)) ? 'SĐT không hợp lệ' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('email', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={currentForm.email || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('userName', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="userName"
                    label="Username"
                    name="userName"
                    disabled={Boolean(currentForm.id)}
                    required
                    value={currentForm.userName || ''}
                    error={(isDirty && !currentForm.userName) || Boolean(currentForm.userName && !isValidUserName(currentForm.userType, currentForm.userName))}
                    helperText={isDirty && !currentForm.userName ?
                        'Không được để trống' : Boolean(currentForm.userName && !isValidUserName(currentForm.userType, currentForm.userName)) ? 'Tên tài khoản có kí tự không phù hợp' : ''}
                />
              </Grid>
              {!currentForm.id && (
                  <Grid item xs={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleUpdate('password', event?.target?.value ?? '')}
                        variant="outlined"
                        fullWidth
                        id="password"
                        label="Mật khẩu"
                        name="password"
                        required
                        disabled={Boolean(currentForm.id)}
                        value={currentForm.password || ''}
                        error={isDirty && !currentForm.password}
                        helperText={isDirty && !currentForm.password ? 'Không được để trống' : ''}
                    />
                  </Grid>
              )}
              {isAdmin && (
                  <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        disabled={!isAdmin}
                        id="combo-box-user-type"
                        options={USER_TYPE_OPTIONS.filter((f) => f.value !== null)}
                        value={USER_TYPE_OPTIONS.find((f) => f.value === currentForm?.userType) || USER_TYPE_OPTIONS[1]}
                        renderInput={(params) => <TextField {...params} label="Loại người dùng"/>}
                        onChange={(_: any, value: any) => {
                          handleUpdate('userType', value?.value)
                        }}
                    />
                  </Grid>
              )}
              {currentForm.id && currentForm.createdAt && (
                  <Grid item xs={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        fullWidth
                        id="createdAt"
                        label="Ngày tạo"
                        name="createdAt"
                        disabled
                        value={moment(currentForm.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                    />
                  </Grid>
              )}
              {currentForm.id && currentForm.userType === 'AGENCY' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                          InputLabelProps={{ shrink: true }}
                          value={currentForm.referralCount ?? 0}
                          variant="outlined"
                          fullWidth
                          disabled
                          name="referralCount"
                          label="Số lượt giới thiệu"
                          type="referralCount"
                          id="referralCount"
                      />
                    </Grid>
                    {currentForm.referralUrl && (
                        <Grid item xs={12}>
                          <img width={500} height={500} src={currentForm.referralUrl}
                               alt="referal-url"/>
                        </Grid>
                    )}
                  </>
              )}
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        disabled={!isAdmin}
                        id="combo-box-province-type"
                        options={provinceList}
                        getOptionLabel={(opt) => opt.name ?? ''}
                        value={provinceList.find((f: AppConfig) => f.code === userAddress?.provinceCode) ?? null}
                        renderInput={(params) => <TextField {...params} label="Tỉnh/Thành phố"/>}
                        onChange={(_: any, value: any) => {
                          updateUserAddress('provinceCode', value?.code)
                        }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        disabled={!isAdmin}
                        id="combo-box-district-type"
                        options={districtList}
                        getOptionLabel={(opt) => opt.fullName ?? ''}
                        value={districtList.find((f: AppConfig) => f.code === userAddress?.districtCode) ?? null}
                        renderInput={(params) => <TextField {...params} label="Quận/Huyện"/>}
                        onChange={(_: any, value: any) => {
                          updateUserAddress('districtCode', value?.code)
                        }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        disabled={!isAdmin}
                        id="combo-box-ward-type"
                        options={wardList}
                        getOptionLabel={(opt: AppConfig) => opt.fullName ?? ''}
                        value={wardList.find((f: AppConfig) => f.code === userAddress?.wardCode) ?? null}
                        renderInput={(params) => <TextField {...params} label="Phường/Xã"/>}
                        onChange={(_: any, value: any) => {
                          updateUserAddress('wardCode', value?.code)
                        }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => updateUserAddress('address', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="address"
                    label="Địa chỉ"
                    name="address"
                    value={userAddress.address || ''}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
              color="warning"
              variant="outlined"
              onClick={handleClose}>
            Đóng
          </Button>
          <Button
              variant="contained"
              onClick={handleCreateOrUpdate}>
            {`${currentForm.id ? 'Cập nhật' : 'Tạo'}`}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default CreateOrUpdateUserDialog;