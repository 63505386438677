import ExcelJS from 'exceljs';
import { getUsers } from '../Common';
import { Address, User, USER_TYPE_OPTIONS } from '../../pages/User/model';
import _ from 'lodash';

type Response<T> = {
  results: T[];
  total: number;
}

const getTypeUser = (userType?: string) => {
  if (!userType) {
    return ' ';
  }
  return USER_TYPE_OPTIONS.find((f) => f.value === userType)?.label ?? ' ';
}

const getAddressText = (address: Address) => {
  if (_.isEmpty(address)) {
    return '';
  }
  return [address.address, address.ward, address.district, address.province]
  .filter((a) => !_.isEmpty(a))
  .join(', ');
}

export const exportUser = async (filter: any): Promise<boolean> => {

  const excelWorkBook = new ExcelJS.Workbook();
  const mainSheet = excelWorkBook.addWorksheet('Danh sách người dùng');
  const allUsers = await getAllUser(filter);
  const headerName = ['Tên', 'Tài khoản', 'SĐT', 'Email', 'Loại người dùng', 'Địa chỉ'];
  mainSheet.columns = headerName.map((i) => ({ header: i, width: 15 }));
  const dataRows = allUsers.map((user) => {
    return [user.name, user.userName, user.phoneNumber, user.email, getTypeUser(user.userType), getAddressText(user.address)];
  })

  mainSheet.addRows(dataRows);

  // Export file
  return excelWorkBook.xlsx.writeBuffer()
  .then(function (buffer) {
    downloadBlobFile(
        new Blob([buffer],
            { type: 'application/octet-stream' }),
        `Danh sách người dùng.xlsx`
    );
    return true;
  }).catch(e => {
    console.error(e);
    return false;
  });
}

const getAllUser = async (filter: any): Promise<User[]> => {
  const listUser: User[] = []
  let currentPage = 1;
  const maxSizeFilter = { ...filter, size: 5000 };
  let response = await getUsers(maxSizeFilter) as Response<User>;
  const total = response.total;
  listUser.push(...response.results);
  while (listUser.length < total) {
    currentPage++;
    maxSizeFilter.page = currentPage;
    response = await getUsers(maxSizeFilter) as Response<User>;
    if (!_.isEmpty(response.results)) {
      listUser.push(...response.results);
    }
  }
  return listUser;
}

export const downloadBlobFile = (blob: Blob, filename: string) => {
  const a = document.createElement('a');
  a.setAttribute('download', filename);
  a.setAttribute('href', window.URL.createObjectURL(blob));
  a.click();
};
