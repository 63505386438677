import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import React, { useCallback, useEffect, useState } from 'react';
import { BannerCreateRequest, BannerType } from '../model';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import { isAuthAdmin } from '../../../service/Auth';
import { createBanner, updateBanner } from '../../../service/Common';
import { enqueueSnackbar } from 'notistack';
import AttachmentArea from '../../../components/AttachmentArea';
import { LocalAttachment } from '../../../service/Common/models';
import LoadingBackdrop from '../../../components/global/LoadingBackdrop';
import _ from 'lodash';


interface Props {
  open: boolean;
  detail?: Partial<BannerCreateRequest>;
  onClose: () => void;
  needRetrigger: () => void;
}

const CreateOrUpdateBannerDialog = (props: Props) => {
  const authProfile = useSelector(getProfile);
  const isAdmin = isAuthAdmin(authProfile);
  const [currentForm, setCurrentForm] = useState<Partial<BannerCreateRequest>>({});
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (props.detail) {
      setCurrentForm({
        ...props.detail,
        imageDtos: props.detail.imageUrl ? [{ id: uuid(), url: props.detail.imageUrl }] : []
      });
    } else {
      setCurrentForm({});
    }
  }, [props.detail]);
  const handleUpdate = (key: string, value: any) => {
    setCurrentForm((cur) => ({ ...cur, [key]: value }));
  };
  const handleInsertFile = (info: LocalAttachment) => {
    setCurrentForm((cur) => ({
      ...cur,
      imageDtos: [
        ...(cur.imageDtos ?? []),
        { id: info.uuid, url: info.objectURL, blob: info.blob }
      ]
    }))
  };

  const handleRemoveFile = ({ uuid }: Partial<LocalAttachment>) => {
    setCurrentForm((cur) => ({
      ...cur,
      imageDtos: (cur.imageDtos ?? []).filter((img) => img.id !== uuid)
    }))
  }

  const handleClose = () => {
    props.onClose();
    setCurrentForm({});
    setIsDirty(false);
  }
  const isInvalidForm = () => {
    return _.isEmpty(currentForm.name);
  }
  const handleCreateOrUpdate = useCallback(() => {
    setIsDirty(true);
    if (isInvalidForm()) {
      enqueueSnackbar('Thiếu trường thông tin bắt buộc', { variant: 'warning' });
      return;
    }
    setLoading(true);
    if (!currentForm.id) {
      createBanner(currentForm)
      .then(() => {
        enqueueSnackbar('Tạo banner thành công', { variant: 'success' });
        handleClose();
        props.needRetrigger();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Tạo banner thất bại', { variant: 'error' });
      }).finally(() => setLoading(false));
    } else {
      updateBanner(currentForm.id, currentForm)
      .then(() => {
        enqueueSnackbar('Cập nhật banner thành công', { variant: 'success' });
        props.needRetrigger();
        handleClose();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Cập nhật banner thất bại', { variant: 'error' });
      }).finally(() => setLoading(false));
    }
  }, [currentForm]);
  return (
      <Dialog
          open={props.open}
          onClose={handleClose}
          maxWidth="xl"
          fullWidth
      >
        <DialogTitle
            id="scroll-dialog-title">
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 700, fontSize: 20, flexGrow: 1 }}>
              {`${props.detail?.id ? 'Cập nhật' : 'Tạo mới'} banner`}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: 14 }} style={{ paddingTop: 8 }}>
              Banner nổi bật
            </Typography>
            <Switch
                checked={currentForm.highlight ?? false}
                onChange={(_, val) => handleUpdate('highlight', val)}
                name="gilad"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <LoadingBackdrop open={isLoading}/>
          <Box style={{ margin: 20 }}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('name', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Tên"
                    name="name"
                    required
                    value={currentForm.name || ''}
                    error={isDirty && !currentForm.name}
                    helperText={isDirty && !currentForm.name ? 'Không được để trống' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('link', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="link"
                    label="Link"
                    name="name"
                    multiline
                    value={currentForm.link || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={8}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleUpdate('description', event?.target?.value ?? '')}
                        variant="outlined"
                        fullWidth
                        id="description"
                        label="Mô tả"
                        name="description"
                        multiline
                        value={currentForm.description || ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        options={BannerType}
                        value={BannerType.find((f) => f.value === currentForm?.type) || BannerType[0]}
                        renderInput={(params) => <TextField {...params} label="Loại banner"/>}
                        onChange={(_: any, value: any) => {
                          handleUpdate('type', value?.value ?? '');
                        }}
                    />

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ fontWeight: '700' }}>Hình ảnh</Box>
                  </Grid>
                  <Grid item xs={12}>
                    <AttachmentArea
                        capturedImages={currentForm.imageDtos ?? []}
                        insertAttachment={handleInsertFile}
                        removeAttachment={handleRemoveFile}
                        maxFilesAllowed={1}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
              color="warning"
              variant="outlined"
              onClick={handleClose}>
            Đóng
          </Button>
          <Button
              variant="contained"
              onClick={handleCreateOrUpdate}>
            {`${currentForm.id ? 'Cập nhật' : 'Tạo'}`}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default CreateOrUpdateBannerDialog;