import { useEffect } from 'react';
import { init as initAuth } from './service/Auth';
import { getAuthStatus } from './store/auth/selector';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import App from './App';
import Login from './pages/Login';
import { clearAuth, setAuth } from './store/auth';
import ResetPassword from './pages/ResetPassword';
import PolicyPage from './pages/Policy';
import DeleteAccountPolicyPage from './pages/PolicyDeleteAccount';
import { useLocation } from 'react-router-dom';

function AuthApp() {
  const authStatus = useSelector(getAuthStatus);
  const dispatch = useDispatch();
  const location =  useLocation();

  useEffect(() => {
    initAuth()
    .then((authState) => {
      if (authState.authenticated) {
        dispatch(
            setAuth({
              status: 'authenticated',
              profile: {
                id: _.get(authState, 'data.id'),
                userName: _.get(authState, 'data.userName'),
                userType: _.get(authState, 'data.userType'),
                email: _.get(authState, 'data.email')
              }
            })
        );
      } else {
        dispatch(clearAuth({}));
      }
    })
    .catch((err) => {
      dispatch(clearAuth({}));
    });
  }, []);
  if (location.pathname.includes('docs/policy')) {
    return <PolicyPage/>
  }
  if (location.pathname.includes('docs/delete-account')) {
    return <DeleteAccountPolicyPage/>
  }
  if (authStatus !== 'authenticated' && !location.pathname.includes('reset-password')) {
    return <Login/>;
  }
  return <App/>;
}

export default AuthApp;
