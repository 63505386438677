import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import { getPromotions } from '../../../service/Common';
import LoadingBackdrop from '../../../components/global/LoadingBackdrop';
import { PromotionType } from '../model';

interface SearchFormProps {
  filter: { [key: string]: any },
  setFilter: (value: any) => void,
  setData: (value: any) => void
  onCreate: () => void
}

const SearchForm = (props: SearchFormProps) => {
  const authProfile = useSelector(getProfile);
  const [loading, setLoading] = useState(false);

  const handleUpdateFilter = (key: string, value: any) => {
    props.setFilter((cur: any) => ({ ...cur, [key]: value }));
  }
  const handleSearch = useCallback(() => {
    setLoading(true);
    getPromotions(props.filter)
    .then((res) => {
      handleUpdateFilter('total', res.total);
      props.setData(res.results);
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      setLoading(false);
    })
  }, [props.filter]);

  useEffect(() => {
    handleSearch();
  }, [props.filter.page, props.filter.trigger]);

  return (
      <Box>
        <LoadingBackdrop open={loading}/>
        <Grid container columnSpacing={3}>
          <Grid item xs={3}>
            <TextField
                InputLabelProps={{ shrink: true }}
                onChange={(event) => handleUpdateFilter('keyword', event?.target?.value ?? '')}
                variant="outlined"
                fullWidth
                id="keyword"
                label="Tìm kiếm"
                name="keyword"
                value={props.filter.keyword || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
                disablePortal
                options={PromotionType}
                value={PromotionType.find((f) => f.value === props.filter.type) || null}
                renderInput={(params) => <TextField {...params} label="Loại khuyến mãi"/>}
                onChange={(_: any, value: any) => {
                  handleUpdateFilter('type', value?.value)
                }}
            />
          </Grid>
          <Grid item xs container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Tooltip title="Đặt lại tìm kiếm">
                <IconButton onClick={() => {
                  props.setFilter((cur: any) => ({ ...cur, keyword: '', trigger: !cur.trigger }));
                }}>
                  <RotateLeftIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" size="medium" onClick={handleSearch}>
                Tìm kiếm
              </Button>
            </Grid>
            <Grid item>
              <Button color="success" variant="contained" onClick={props.onCreate} size="medium">
                Tạo mới
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
  );
}

export default SearchForm;