import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import _ from 'lodash';
import { getUsers } from '../../../service/Common';
import LoadingBackdrop from '../../../components/global/LoadingBackdrop';
import { USER_TYPE_OPTIONS } from '../model';
import { exportUser } from '../../../service/ExcelJS';
import { enqueueSnackbar } from 'notistack';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

interface SearchFormProps {
  filter: { [key: string]: any },
  setFilter: (value: any) => void,
  setData: (value: any) => void
  onCreate: () => void
}

const SearchForm = (props: SearchFormProps) => {
  const authProfile = useSelector(getProfile);
  const [loading, setLoading] = useState(false);
  const userTypeOptions = useMemo(() => {
    if (_.get(authProfile, 'userType') !== 'ADMIN') {
      return USER_TYPE_OPTIONS.filter((ut) => ut.value !== 'ADMIN');
    }
    return USER_TYPE_OPTIONS;
  }, [authProfile]);

  const handleUpdateFilter = (key: string, value: any) => {
    props.setFilter((cur: any) => ({ ...cur, [key]: value }));
  }
  const handleSearch = useCallback(() => {
    setLoading(true);
    getUsers(props.filter)
    .then((res) => {
      handleUpdateFilter('total', res.total);
      props.setData(res.results);
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      setLoading(false);
    })
  }, [props.filter]);

  useEffect(() => {
    handleSearch();
  }, [props.filter.page, props.filter.trigger]);

  const exportUsers = useCallback(() => {
    setLoading(true);
    exportUser(props.filter)
      .then(() => {
        enqueueSnackbar('Xuất danh sách người dùng thành công', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Xuất danh sách người dùng thất bại', { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      })
  }, [props.filter]);
  return (
      <Box>
        <LoadingBackdrop open={loading}/>
        <Grid container columnSpacing={3}>
          <Grid item xs={3}>
            <TextField
                InputLabelProps={{ shrink: true }}
                onChange={(event) => handleUpdateFilter('keyword', event?.target?.value ?? '')}
                variant="outlined"
                fullWidth
                id="keyword"
                label="Tìm kiếm"
                name="keyword"
                value={props.filter.keyword || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
                disablePortal
                id="combo-box-user-type"
                options={userTypeOptions}
                value={userTypeOptions.find((f) => f.value === props.filter?.userType) || userTypeOptions[0]}
                renderInput={(params) => <TextField {...params} label="Loại người dùng"/>}
                onChange={(_: any, value: any) => {
                  props.setFilter((cur: any) => ({ ...cur, userType: value?.value ?? '' }));
                }}
            />
          </Grid>
          <Grid item xs container justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Tooltip title="Đặt lại tìm kiếm">
                <IconButton onClick={() => {
                  props.setFilter((cur: any) => ({
                    ...cur,
                    keyword: '',
                    userType: null,
                    trigger: !cur.trigger
                  }));
                }}>
                  <RotateLeftIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" size="medium" onClick={handleSearch}>
                Tìm kiếm
              </Button>
            </Grid>
            <Grid item>
              <Button color="success" variant="contained" onClick={props.onCreate} size="medium">
                Tạo mới
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Xuất danh sách người dùng">
                <IconButton onClick={exportUsers}>
                  <SystemUpdateAltIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
  );
}

export default SearchForm;