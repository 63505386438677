import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PromotionCreateRequest, PromotionType } from '../model';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import { isAuthAdmin } from '../../../service/Auth';
import RichTextEditor from '../../../components/RichtextEditor';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { createPromotion, updatePromotion } from '../../../service/Common';
import { enqueueSnackbar } from 'notistack';
import AttachmentArea from '../../../components/AttachmentArea';
import { LocalAttachment } from '../../../service/Common/models';
import LoadingBackdrop from '../../../components/global/LoadingBackdrop';
import _ from 'lodash';

interface Props {
  open: boolean;
  detail?: Partial<PromotionCreateRequest>;
  onClose: () => void;
  needRetrigger: () => void;
}

const CreateOrUpdatePromotionDialog = (props: Props) => {
  const authProfile = useSelector(getProfile);
  const isAdmin = isAuthAdmin(authProfile);
  const [currentForm, setCurrentForm] = useState<Partial<PromotionCreateRequest>>({});
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    if (props.detail) {
      setCurrentForm({
        ...props.detail,
        cacheImages: (props.detail.imageDtos ?? []),
        cacheDescription: props.detail.description
      });
    } else {
      setCurrentForm({});
    }
  }, [props.detail]);
  const handleUpdate = (key: string, value: any) => {
    setCurrentForm((cur) => ({ ...cur, [key]: value }));
  };
  const handleInsertFile = (info: LocalAttachment) => {
    setCurrentForm((cur) => ({
      ...cur,
      imageDtos: [
        ...(cur.imageDtos ?? []),
        { id: info.uuid, url: info.objectURL, blob: info.blob }
      ]
    }))
  };

  const handleRemoveFile = ({ uuid }: Partial<LocalAttachment>) => {
    setCurrentForm((cur) => ({
      ...cur,
      imageDtos: (cur.imageDtos ?? []).filter((img) => img.id !== uuid)
    }))
  }
  const handleClose = () => {
    props.onClose();
    setCurrentForm({});
    setIsDirty(false);
  }
  const isInvalidForm = () => {
    return _.isEmpty(currentForm.name) || !currentForm.startDate || !currentForm.endDate;
  }
  const handleCreateOrUpdate = useCallback(() => {
    setIsDirty(true);
    if (isInvalidForm()) {
      enqueueSnackbar('Thiếu trường thông tin bắt buộc', { variant: 'warning' });
      return;
    }
    setLoading(true);
    if (!currentForm.id) {
      createPromotion(currentForm)
      .then(() => {
        enqueueSnackbar('Tạo khuyến mãi thành công', { variant: 'success' });
        handleClose();
        props.needRetrigger();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Tạo khuyến mãi thất bại', { variant: 'success' });
      }).finally(() => setLoading(false));
    } else {
      updatePromotion(currentForm.id, currentForm)
      .then(() => {
        enqueueSnackbar('Cập nhật khuyến mãi thành công', { variant: 'success' });
        props.needRetrigger();
        handleClose();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Cập nhật khuyến mãi thất bại', { variant: 'error' });
      }).finally(() => setLoading(false));
    }
  }, [currentForm]);
  return (
      <Dialog
          open={props.open}
          onClose={handleClose}
          maxWidth="xl"
          fullWidth
      >
        <DialogTitle
            id="scroll-dialog-title">
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 700, fontSize: 20, flexGrow: 1 }}>
              {`${props.detail?.id ? 'Cập nhật' : 'Tạo mới'} khuyến mãi`}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: 14 }} style={{ paddingTop: 8 }}>
              Khuyến mãi nổi bật
            </Typography>
            <Switch
                checked={currentForm.highlight ?? false}
                onChange={(_, val) => handleUpdate('highlight', val)}
                name="gilad"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <LoadingBackdrop open={isLoading}/>
          <Box style={{ margin: 20 }}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('name', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Tên"
                    name="name"
                    value={currentForm.name || ''}
                    required
                    error={isDirty && !currentForm.name}
                    helperText={isDirty && !currentForm.name ? 'Không được để trống' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('partner', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="partner"
                    label="Tên đối tác"
                    name="partner"
                    value={currentForm.partner || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleUpdate('discountAmount', event?.target?.value ?? '')}
                        variant="outlined"
                        fullWidth
                        id="discountAmount"
                        label="Số tiền giảm"
                        name="discountAmount"
                        type="number"
                        value={currentForm.discountAmount || 0}
                        inputProps={{
                          startAdornment: <InputAdornment position="end">VNĐ</InputAdornment>,
                        }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleUpdate('discountPercent', event?.target?.value ?? '')}
                        variant="outlined"
                        fullWidth
                        id="discountPercent"
                        label="Mức giảm"
                        name="discountPercent"
                        type="number"
                        value={currentForm.discountPercent || 0}
                        inputProps={{
                          startAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        options={PromotionType}
                        value={PromotionType.find((f) => f.value === currentForm?.type) || null}
                        renderInput={(params) => <TextField {...params} label="Loại khuyến mãi"/>}
                        onChange={(_: any, value: any) => {
                          handleUpdate('type', value?.value)
                        }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                        disableFuture
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            required: true,
                            error: isDirty && !currentForm.startDate,
                            helperText: isDirty && !currentForm.startDate ? 'Không được để trống' : ''
                          }
                        }}
                        label="Ngày bắt đầu"
                        value={currentForm.startDate ? moment(currentForm.startDate) : null}
                        onChange={(newValue: any) => handleUpdate('startDate', newValue?.toDate()?.getTime() ?? 0)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                        format="DD-MM-YYYY"
                        label="Ngày kết thúc"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            required: true,
                            error: isDirty && !currentForm.endDate,
                            helperText: isDirty && !currentForm.endDate ? 'Không được để trống' : ''
                          }
                        }}
                        value={currentForm.endDate ? moment(currentForm.endDate) : null}
                        onChange={(newValue: any) => handleUpdate('endDate', newValue?.toDate()?.getTime() ?? 0)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RichTextEditor
                    label="Mô tả khuyến mãi"
                    value={currentForm.cacheDescription}
                    onChange={(val) => handleUpdate('description', val.html)}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ fontWeight: '700' }}>Danh sách hình ảnh</Box>
                  </Grid>
                  <Grid item xs={12}>
                    <AttachmentArea
                        capturedImages={currentForm.imageDtos ?? []}
                        insertAttachment={handleInsertFile}
                        removeAttachment={handleRemoveFile}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
              color="warning"
              variant="outlined"
              onClick={handleClose}>
            Đóng
          </Button>
          <Button
              variant="contained"
              onClick={handleCreateOrUpdate}>
            {`${currentForm.id ? 'Cập nhật' : 'Tạo'}`}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default CreateOrUpdatePromotionDialog;