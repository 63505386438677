import React, { useRef } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import { Attachment, LocalAttachment } from '../../service/Common/models';


interface AttachmentAreaProps {
  capturedImages: Array<Attachment>;
  insertAttachment: ({
                       objectURL, fileName, uuid, fileSize, blob
                     }: LocalAttachment) => void;
  removeAttachment: ({ uuid }: Partial<LocalAttachment>) => void;
  disabled?: boolean;
  maxFilesAllowed?: number;
}

const AttachmentArea = (props: AttachmentAreaProps) => {
  const {
    capturedImages,
    disabled,
    insertAttachment,
    removeAttachment,
    maxFilesAllowed
  } = props;
  const fileInputRef = useRef<any>(null);
  const onFileUpload = () => {
    const file: File = fileInputRef.current.files[0];
    const objectURL = URL.createObjectURL(file);
    insertAttachment({
      objectURL, fileName: file.name, uuid: uuidv4(), fileSize: file.size, blob: file
    });
  };
  const onRemoveAttachment = (uuid: string) => (e: any) => {
    removeAttachment({ uuid });
    e.stopPropagation();
  };
  return (
      <>
        <fieldset style={{
          borderRadius: '8px',
          border: `1px solid`,
          margin: '10px 0'
        }}>
          <Grid container spacing={3} style={{ margin: 10 }}>
            {capturedImages.map((item) => {
              return (
                  <Grid item key={item.id} style={{ padding: 0, marginRight: 15 }}>
                    <Tooltip title={item.name}>
                      <Box sx={{
                        cursor: 'pointer',
                        height: 80,
                        borderRadius: 4,
                        position: 'relative',
                        padding: 0,
                        '& img': {
                          height: '100%'
                        },
                        '& .remove-btn': {
                          position: 'absolute',
                          right: '-20px',
                          top: '-25px',
                          display: 'none'
                        },
                        '&:hover': {
                          '& .remove-btn': {
                            display: 'block'
                          }
                        }
                      }}>
                        <>
                          <img
                              alt={`${item.id}`}
                              src={item.url}
                              style={{ marginRight: 15 }}
                          />
                          <IconButton color="error" className="remove-btn"
                                      onClick={onRemoveAttachment(item.id)}
                                      disabled={Boolean(disabled)}>
                            <HighlightOffIcon fontSize="large"/>
                          </IconButton>
                        </>
                      </Box>
                    </Tooltip>
                  </Grid>
              );
            })}
            <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                style={{ padding: 0 }}
            >
              {capturedImages.length < (maxFilesAllowed ?? Number.MAX_SAFE_INTEGER) && (
                  <Tooltip title={'Thêm hình ảnh'}>
                    <IconButton
                        onClick={() => fileInputRef?.current?.click()}
                        disabled={Boolean(disabled)}>
                      <AddIcon/>
                    </IconButton>
                  </Tooltip>)}
              <input
                  ref={fileInputRef}
                  onChange={onFileUpload}
                  type="file"
                  name="file"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onClick={(event) => {
                    // @ts-ignore
                    if (event?.target?.value) {
                      // @ts-ignore
                      event.target.value = '';
                    }
                  }}
              />
            </Grid>
          </Grid>
        </fieldset>
      </>
  );
};

export default AttachmentArea;
