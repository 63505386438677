import React from 'react';
import './styles.css';
import { login } from '../../service/Auth';
import { useDispatch } from 'react-redux';
import { clearAuth, setAuth } from '../../store/auth';
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://songyen.com/">
          Song Yến
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}


const Login = () => {
  const [account, setAccount] = React.useState({ username: '', password: '' });
  const dispatch = useDispatch();
  const handelAccount = (property: 'username' | 'password', event: any) => {
    const accountCopy = { ...account };
    accountCopy[property] = event.target.value;

    setAccount(accountCopy);

  }
  const handleSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    login(account.username, account.password)
    .then((authState) => {
      if (authState.authenticated) {
        dispatch(
            setAuth({
              status: 'authenticated',
              profile: { ...authState.data }
            })
        );
      } else {
        dispatch(clearAuth({}));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(clearAuth({}));
    });
  };


  return (
      <Grid container component="main" style={{
        height: '100vh',
        backgroundImage: `url('/login-background.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'right',
      }}>
        <CssBaseline/>
        <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={1}
            square
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Avatar>
              <LockIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form style={{ width: '100%' }} noValidate>
              <TextField
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => handelAccount('username', event)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoFocus
              />
              <TextField
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => handelAccount('password', event)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
              />
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
              >
                Đăng nhập
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/reset-password" variant="body2">
                    Quên mật khẩu ?
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright/>
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
  );
};

export default Login;
