import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import { GrCheckboxSelected } from 'react-icons/gr';

const ActionCell = (params: GridRenderCellParams, onClick?: (val: any) => void) => {
  const user = params.row;
  return (
      <div style={{ display: 'inline-flex' }}>
        <IconButton onClick={() => onClick && onClick({ ...user, actionType: 'DELETE' })}>
          <DeleteForeverIcon color="error"/>
        </IconButton>
        {user.userType === 'AGENCY' && (
          <IconButton onClick={() => onClick && onClick({ ...user, actionType: 'ACTIVATED' })}>
            <GrCheckboxSelected color="success"/>
          </IconButton>
        )}
      </div>
  );
}

export default ActionCell;