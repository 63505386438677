import React, { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { Button, CssBaseline, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import Header from '../../components/PageHeader';
import { requestSendEmail, resetPassword } from '../../service/Common';
import { enqueueSnackbar } from 'notistack';
import _ from 'lodash';
import LoadingBackdrop from '../../components/global/LoadingBackdrop';
import { useNavigate } from 'react-router-dom';
import store from '../../store';
import { clearAuth } from '../../store/auth';
import { logout } from '../../service/Auth';

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://songyen.com/">
          Song Yến
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

interface ResetPasswordRequest {
  userName: string;
  newPassword: string;
  reNewPassword: string;
  otp: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const [sendRequest, setSendRequest] = useState(false);
  const [form, setForm] = useState<Partial<ResetPasswordRequest>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    logout().then(() => store.dispatch(clearAuth({})))
  }, []);

  const handleUpdate = (key: string, value: any) => {
    setForm({ ...form, [key]: value });
  }

  const doRequestPasswordReset = useCallback(() => {
    if ((!form.userName)) {
      return;
    }
    setIsLoading(true);
    requestSendEmail(form.userName)
    .then(() => {
      enqueueSnackbar('Kiểm tra email để lấy lại mật khẩu', { variant: 'info' });
      setSendRequest(true);
    })
    .catch((e) => {
      console.error(e);
      enqueueSnackbar('Gửi yêu cầu thất bại', { variant: 'error' });
    }).finally(() => setIsLoading(false));
  }, [form.userName])
  const handleUpdatePassword = useCallback(() => {
        setIsLoading(true);
        resetPassword(form)
        .then(() => {
          enqueueSnackbar('Lấy lại mật khẩu thành công', { variant: 'success' });
          // navigate('');
          window.location.href = '/';
        }).catch((e) => {
          enqueueSnackbar('Lấy lại mật khẩu thất bại', { variant: 'error' });
        }).finally(() => setIsLoading(false));
      },
      [form]);

  return (
      <Grid
          container
          component="main"
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
          }}>
        <CssBaseline/>
        <LoadingBackdrop open={isLoading}/>
        <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            item
            xs={12}
            sm={8}
            md={8}
            component={Paper}
            elevation={1}
        >
          <Header title="Lấy lại mật khẩu" subtitle=""/>
          <Grid container columnSpacing={3}>
            <Grid item xs={9}>
              <TextField
                  value={form.userName}
                  onChange={(event) => handleUpdate('userName', event?.target?.value ?? '')}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Tài khoản"
                  name="username"
                  autoFocus
              />
            </Grid>
            <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={doRequestPasswordReset}
                  disabled={_.isEmpty(form.userName)}
              >
                {sendRequest ? 'Gửi lại mã OTP' : 'Gửi OTP'}
              </Button>
            </Grid>
            {sendRequest && (
                <>
                  <Grid item xs={9}>
                    <TextField
                        value={form.otp}
                        onChange={(event) => handleUpdate('otp', event?.target?.value ?? '')}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="otp"
                        label="Mã xác thực"
                        name="otp"
                        autoFocus
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                        value={form.newPassword}
                        onChange={(event) => handleUpdate('newPassword', event?.target?.value ?? '')}
                        variant="outlined"
                        margin="normal"
                        required
                        type="password"
                        fullWidth
                        id="new-pass"
                        label="Mật khẩu mới"
                        name="new-pass"
                        autoFocus
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                        value={form.reNewPassword}
                        onChange={(event) => handleUpdate('reNewPassword', event?.target?.value ?? '')}
                        variant="outlined"
                        margin="normal"
                        required
                        type="password"
                        fullWidth
                        id="re-new-pass"
                        label="Xác nhận mật khẩu mới"
                        name="re-new-pass"
                        autoFocus
                        error={sendRequest && Boolean(form.newPassword) && form.newPassword !== form.reNewPassword}
                        helperText={
                          `${sendRequest && Boolean(form.newPassword) && form.newPassword !== form.reNewPassword
                              ? 'Mật khẩu không trùng khớp' : ''}`
                        }
                    />
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      style={{
                        margin: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdatePassword}
                    >
                      Đổi mật khẩu
                    </Button>
                  </Grid>
                </>
            )}
          </Grid>
        </Grid>
      </Grid>
  );
};

export default ResetPassword;
