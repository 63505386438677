import React from 'react';
import { Box, useTheme } from '@mui/material';

const NotFound = () => {
  const theme = useTheme();
  return (
      <Box
        style={{ padding: 20 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <div className="not-found">
          <img src="/not-found.png" alt="not-found" width={500} height={500}/>
        </div>
      </Box>
  );
};

export default NotFound;
