import axios from 'axios';
import config from '../../configs/app-config';
import { getToken } from '../Auth';
import { enqueueSnackbar, useSnackbar } from 'notistack';

const instance = axios.create({
  baseURL: config.apiUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Device-Type': 'WEB-APP'
  }
});

// Add a request interceptor
instance.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      console.log(error);
      return Promise.reject(error);
    }
);

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
      const status = response.status;

      // if (status === 401) {
      //   store.dispatch(clearAuth({}));
      //   return Promise.reject(response);
      // }

      if (status < 200 || status > 299) {
        console.log(response);
        return Promise.reject(response);
      }
      return response.data;
    },
    function (error) {
      enqueueSnackbar('Có lỗi xảy ra', { variant: 'error'});
      console.log(error);
      return Promise.reject(error);
    }
);

async function secureRequest(requestConfig: any) {
  return getToken().then((token) => {
    return {
      data: {},
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${token}`,
        ...config.getAuthRequestHeaders(),
        ...requestConfig?.headers,
        'Access-Control-Allow-Origin': '*'
      }
    };
  });
}

export async function request(config: any): Promise<any> {
  return instance.request(config);
}

export async function get(url: string, requestConfig?: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) => instance.get(url, authConfig));
}

export async function requestDelete(url: string, requestConfig: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) => instance.delete(url, authConfig));
}

export async function post(url: string, data: any, requestConfig?: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) =>
      instance.post(url, JSON.stringify(data), authConfig)
  );
}

export async function postForm(url: string, data: any, requestConfig?: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) =>
      instance.postForm(url, data, authConfig)
  );
}

export async function patch(url: string, data: any, requestConfig?: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) =>
      instance.patch(url, JSON.stringify(data), authConfig)
  );
}

export async function put(url: string, data: any, requestConfig?: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) =>
      instance.put(url, JSON.stringify(data), authConfig)
  );
}

export async function putForm(url: string, data: any, requestConfig?: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) =>
      instance.putForm(url, data, authConfig)
  );
}


export async function sendDelete(url: string, requestConfig?: any): Promise<any> {
  return secureRequest(requestConfig).then((authConfig) => instance.delete(url, authConfig));
}

export const encodeObject = (givenObj: any): string | null => {
  if (!givenObj) {
    return null;
  }
  const jsonString = JSON.stringify(givenObj);
  const utf8String = unescape(encodeURIComponent(jsonString));

  const byteArray = [];
  for (let i = 0; i < utf8String.length; i++) {
    byteArray.push(utf8String.charCodeAt(i));
  }
  const byteCharacters = new Uint8Array(byteArray);
  const regularArray = Array.from(byteCharacters);
  return btoa(String.fromCharCode.apply(null, regularArray));
};
