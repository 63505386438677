import { useRoutes } from 'react-router-dom';
import './App.css';
import routes from './pages/routes';


function App() {
  // const location = useLocation();
  // const [previousPath, setPreviousPath] = useState<string | null>(null);
  //
  //
  // useEffect(() => {
  //   loadConfig();
  // }, []);

  return useRoutes(routes);
}

export default App;
