import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingBackdropProps {
  open: boolean;
}
const LoadingBackdrop = (props: LoadingBackdropProps) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 999999}}
      open={props.open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackdrop;