import { createSlice } from '@reduxjs/toolkit';

export type Profile = {
  id: string;
  username: string;
  fullName: string;
  email: string;
  avatar?: string;
};

export type AuthModel = {
  status: 'authenticated' | 'authenticating' | 'un-authenticated';
  profile: Profile | undefined;
  roles: string[];
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    status: 'un-authenticated',
    profile: undefined,
    roles: []
  },
  reducers: {
    setAuth: (state, action) => {
      state.status = action.payload.status;
      state.profile = action.payload.profile;
      state.roles = action.payload.roles;
    },
    clearAuth: (state, action) => {
      state.status = 'un-authenticated';
      state.profile = undefined;
      state.roles = [];
    }
  }
});

export const { setAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;
