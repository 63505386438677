// import { Variant } from 'react-bootstrap/types';
import { DEFAULT_PAGE_SIZE } from '../../utils/constants';
import { NotificationDto } from '../../service/Common/models';

export interface LayoutState {
  showSideBar: boolean;
}
export interface SnackbarMessage {
  id: string;
  // type: Variant;
  message: string;
  title?: string;
  createdAt: number;
}

export interface CrumbPath {
  title: string;
  path: string;
  disableClick?: boolean;
}

export interface NotificationFiler {
  total: number;
  page: number;
  size: number;
  unread?: boolean;
  totalPages: number;
}

export const INITIAL_FILTER_STATE: NotificationFiler = {
  total: 0,
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  totalPages: 0
};

export interface NotificationState {
  filter: NotificationFiler;
  data: NotificationDto[];
}

export const INITIAL_NOTIFICATION_STATE: NotificationState = {
  filter: INITIAL_FILTER_STATE,
  data: []
};

export const INITIAL_LAYOUT_STATE: LayoutState = {
  showSideBar: true
}

export interface UiState {
  messages: SnackbarMessage[];
  currentParentPath: CrumbPath;
  notification: NotificationState;
  layoutState: LayoutState;
}

export const BASE_CRUMB_PATH: CrumbPath = {
  title: 'Home',
  path: '/'
};

export const INITIAL_UI_STATE: UiState = {
  messages: [],
  currentParentPath: BASE_CRUMB_PATH,
  notification: INITIAL_NOTIFICATION_STATE,
  layoutState: INITIAL_LAYOUT_STATE
};
