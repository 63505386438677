import { Attachment } from "../../service/Common/models"

export type Banner = {
  id: string,
  name: string,
  link: string,
  type: string,
  imageUrl: string,
  description: string,
  highlight: boolean,
  imageDtos: Attachment[],

}


export type BannerCreateRequest = Banner & {
  // categories: string[],
  // cacheDescription: string

}

export const BannerType = [
  { label: 'Ngang', value: 'HORIZONTAL' },
  { label: 'Dọc', value: 'VERTICAL' }
]
