import React, { useEffect } from 'react';
import './style.css';

const PolicyPage = () => {
  useEffect(() => {
    document.title = 'Chính sách bảo mật - SongYen';
  }, []);
  return <div style={{ fontSize: '62.5%'}}>
    <main id="main">
      <div className="section">
        <div className="container">
          <h1 id="post-title">Chính sách bảo mật</h1>
          <div className="post-content">
            <p>Hiệu lực từ ngày 30 tháng 04 năm 2024</p>
            <p>Chính sách Bảo Mật này và (các) Phụ lục kèm theo (sau đây gọi chung là “Thông báo”)
              mô tả cách thức SongYen thu thập, sử dụng, xử lý và tiết lộ Dữ Liệu Cá Nhân của bạn
              thông qua việc sử dụng Ứng Dụng Di động, sản phẩm, tính năng và các dịch vụ khác trên
              toàn cầu (gọi chung là “Dịch Vụ”).</p>
            <p>Thông báo này áp dụng cho tất cả các khách hàng, đại lý, nhà cung cấp (sau đây được
              gọi chung là “bạn”, “của bạn”)</p>
            <p>“Dữ Liệu Cá Nhân” là bất kỳ thông tin nào có thể được sử dụng để nhận dạng bạn hoặc
              dựa vào đó mà bạn được xác định. Điều này bao gồm nhưng không giới hạn ở tên, quốc
              tịch, số điện thoại, chi tiết thẻ tín dụng và ngân hàng, sở thích cá nhân, địa chỉ
              email, hình ảnh của bạn, số nhận dạng do chính phủ cấp, dữ liệu sinh trắc học, chủng
              tộc, ngày sinh, tình trạng hôn nhân, tôn giáo, thông tin sức khỏe, xe và thông tin bảo
              hiểm, thông tin việc làm và thông tin tài chính.</p>
            <h2><strong>I. THU THẬP DỮ LIỆU CÁ NHÂN</strong></h2>
            <p>Chúng tôi thu thập Dữ Liệu Cá Nhân về bạn theo nhiều phương pháp được liệt kê dưới
              đây. Chúng tôi cũng có thể kết hợp các Dữ Liệu Cá Nhân đã được thu thập với những Dữ
              Liệu Cá Nhân chúng tôi đang sở hữu. Nếu bạn có hoặc là một bên tham gia nhiều mối quan
              hệ với chúng tôi (ví dụ nếu bạn sử dụng Dịch vụ của chúng tôi xuyên suốt nhiều ngành
              kinh doanh khác nhau, hoặc nếu bạn vừa là đối tác đại lý và cũng là khách hàng trên
              ứng dụng của chúng tôi hoặc trên các ngành kinh doanh khác của chúng tôi), chúng tôi
              sẽ liên kết Dữ liệu Cá nhân của bạn được thu thập xuyên suốt các khả năng đa dạng của
              bạn để tạo điều kiện cho bạn sử dụng Dịch vụ của chúng tôi và cho các Mục đích được mô
              tả bên dưới.</p>
            <p><strong>Bạn cung cấp Dữ Liệu Cá Nhân cho chúng tôi</strong></p>
            <p>Chúng tôi thu thập Dữ Liệu Cá Nhân của bạn khi bạn cung cấp nó một cách tự nguyện cho
              chúng tôi. Ví dụ, bạn có thể cung cấp Dữ Liệu Cá Nhân của bạn cho chúng tôi khi:</p>
            <ul>
              <li>Hoàn tất hồ sơ người dùng hoặc mẫu đăng ký (như tên của bạn, thông tin liên lạc và
                thông tin nhận dạng khác khi cần thiết);
              </li>
              <li>Tương tác với các trang truyền thông xã hội của chúng tôi (như số ID tài khoản
                truyền thông xã hội của bạn, ảnh đại diện và bất kì dữ liệu công khai nào khác);
              </li>
              <li>Tham gia các cuộc thi hoặc sự kiện do chúng tôi tổ chức (như các tranh ảnh, tệp
                tin âm thanh hoặc video mà bạn có thể gửi, có thể bao gồm hình ảnh của chính bạn);
              </li>
              <li>Điền thông tin nhân khẩu trong các cuộc khảo sát (như tuổi, giới tính của bạn, và
                thông tin khác mà bạn có thể tình nguyện như tình trạng hôn nhân, nghề nghiệp và
                thông tin thu nhập của bạn); và
              </li>
            </ul>
            <p><strong>Khi dịch vụ của chúng tôi được sử dụng</strong></p>
            <p>Dữ Liệu Cá Nhân có thể được thu thập thông qua hoạt động bình thường của Ứng Dụng,
              Websites, Dịch Vụ. Một số ví dụ như:</p>
            <ul>
              <li>Vị trí của bạn (để xác định các đại lý gần nhất xung quanh);</li>
              <li>Phản hồi, xếp hạng và khen ngợi;</li>
              <li>Thông tin về cách bạn tương tác với Ứng dụng, Website hoặc Dịch vụ của chúng tôi
                (như các tính năng được sử dụng và nội dung được xem);
              </li>
              <li>Dữ liệu cá nhân bạn nhập trong tin nhắn khi bạn sử dụng tính năng giao tiếp trong
                ứng dụng của chúng tôi; và
              </li>
              <li>Dữ liệu cá nhân có thể được ghi lại thông qua tương tác của bạn với chúng tôi, các
                đại lý của chúng tôi.
              </li>
            </ul>
            <p><strong>Từ các nguồn khác</strong></p>
            <p>Khi chúng tôi thu thập Dữ liệu Cá nhân từ các nguồn khác, bao gồm nhưng không giới
              hạn ở tên của bạn, thông tin liên hệ và thông tin nhận dạng khác khi cần, chúng tôi
              đảm bảo rằng dữ liệu đó được chuyển đến cho chúng tôi phù hợp theo luật pháp hiện
              hành. Những nguồn này bao gồm:</p>
            <ul>
              <li>Từ các chương trình giới thiệu;</li>
              <li>Từ các đối tác kinh doanh của chúng tôi;</li>
              <li>Nguồn dữ liệu công khai có sẵn;</li>
              <li>Nguồn dữ liệu chính phủ;</li>
              <li>Khi bạn sử dụng trò chuyện trong ứng dụng;</li>
              <li>Nhà cung cấp dịch vụ tiếp thị hoặc đối tác.</li>
            </ul>
            <p><strong>Dữ Liệu Cá Nhân nhạy cảm</strong></p>
            <p>Một số Dữ liệu Cá nhân mà chúng tôi thu thập là nhạy cảm theo đặc tính tự nhiên. Điều
              này bao gồm Dữ liệu Cá nhân liên quan đến chủng tộc của bạn, thông tin số CMND/ số thẻ
              căn cước công dân, niềm tin tôn giáo, thông tin lý lịch (bao gồm hồ sơ tài chính và lý
              lịch tư pháp, khi được phép hợp pháp), dữ liệu sức khỏe, khuyết tật, tình trạng hôn
              nhân và dữ liệu sinh trắc học, nếu phù hợp. Chúng tôi chỉ thu thập thông tin này với
              sự đồng thuận của bạn và / hoặc tuân thủ nghiêm ngặt các luật áp dụng hiện hành.</p>


            <h2><strong>II. SỬ DỤNG DỮ LIỆU CÁ NHÂN</strong></h2>
            <p>SongYen có thể sử dụng, kết hợp và xử lý Dữ Liệu Cá Nhân của bạn cho các mục đích sau
              (“Mục Đích”).</p>
            <p><strong>Cung cấp dịch vụ và các tính năng</strong></p>
            <p>Dữ Liệu Cá Nhân của bạn sẽ được sử dụng để cung cấp, cá nhân hóa, duy trì và cải
              thiện Ứng dụng, Websites và Dịch vụ của chúng tôi. Điều này bao gồm sử dụng Dữ Liệu Cá
              Nhân của bạn để:</p>
            <ul>
              <li>Cung cấp cho bạn Dịch Vụ xuyên suốt các lĩnh vực kinh doanh khác nhau của chúng
                tôi;
              </li>
              <li>Tương tác bạn để cung cấp Dịch Vụ;</li>
              <li>Tạo mới, quản trị và cập nhật tài khoản của bạn;</li>
              <li>Tiến hành kiểm tra thẩm định và đánh giá/ phân tích rủi ro;</li>
              <li>Xác minh danh tính của bạn;</li>
              <li>Xác minh tuổi của bạn (khi cần thiết);</li>
              <li>Kích hoạt các tính năng cá nhân hóa Ứng Dụng của bạn, chẳng hạn như danh sách các
                địa điểm yêu thích và các điểm đến trước đó;
              </li>
              <li>Làm cho trải nghiệm của bạn trở nên liền mạch hơn, như tự động điền thông tin đăng
                ký của bạn (như tên hoặc số điện thoại của bạn) từ Dịch vụ này sang Dịch vụ khác
                hoặc khi bạn tham gia vào các cuộc khảo sát của chúng tôi;
              </li>
              <li>Thực hiện các hoạt động nội bộ cần thiết để cung cấp Dịch Vụ, bao gồm khắc phục
                các lỗi phần mềm và sự cố vận hành, tiến hành phân tích dữ liệu, thử nghiệm và
                nghiên cứu, giám sát và phân tích xu hướng sử dụng và hoạt động;
              </li>
              <li>Bảo vệ tính bảo mật hoặc tính toàn vẹn của Dịch Vụ và mọi phương tiện hoặc thiết
                bị được sử dụng để cung cấp Dịch Vụ;
              </li>
              <li>Xử lý và quản lý phần thưởng của bạn;</li>
              <li>Cho phép liên lạc giữa những người dùng của chúng tôi;</li>
              <li>Xử lý, quản lý hoặc xác minh tính đủ điều kiện của bạn cho hoặc áp dụng về các
                chương trình khuyến mãi, phần thưởng và những đăng ký với SongYen;
              </li>
            </ul>
            <p><strong>An toàn và bảo mật</strong></p>
            <p>Chúng tôi sử dụng dữ liệu của bạn để đảm bảo an toàn và an ninh cho các Dịch vụ của
              chúng tôi và cho tất cả người dùng. Điều này bao gồm:</p>
            <ul>
              <li>Xác minh danh tính của bạn khi bạn đăng nhập vào SongYen;</li>
              <li>Sử dụng thiết bị, vị trí, hồ sơ, cách sử dụng và Dữ Liệu Cá Nhân khác để ngăn
                chặn, phát hiện và chống lại các hoạt động gian lận hoặc không an toàn;
              </li>
              <li>Phát hiện, ngăn chặn và truy tố tội phạm.</li>
            </ul>
            <p><strong>Hỗ trợ khách hàng</strong></p>
            <p>Chúng tôi sử dụng Dữ Liệu Cá Nhân để giải quyết các vấn đề hỗ trợ khách hàng. Ví dụ,
              chúng tôi có thể:</p>
            <ul>
              <li>Điều tra và chỉ ra các vấn đề cần quan tâm;</li>
              <li>Theo dõi và cải thiện cách phản hồi từ bộ phận hỗ trợ khách hàng của chúng tôi;
              </li>
              <li>Trả lời các câu hỏi, ý kiến ​​và phản hồi; và</li>
              <li>Thông báo cho bạn về các bước thực hiện để giải quyết các vấn đề hỗ trợ khách
                hàng.
              </li>
            </ul>
            <p><strong>Nghiên cứu phát triển và bảo mật</strong></p>
            <p>Chúng tôi có thể sử dụng Dữ Liệu Cá Nhân mà chúng tôi thu thập để thử nghiệm, nghiên
              cứu, phân tích và phát triển sản phẩm. Điều này cho phép chúng tôi hiểu và phân tích
              nhu cầu và sở thích của bạn, bảo vệ dữ liệu cá nhân của bạn, cải thiện và tăng cường
              an toàn và bảo mật cho các Dịch vụ của chúng tôi, phát triển các tính năng, sản phẩm
              và dịch vụ mới và tạo điều kiện cho các giải pháp tài chính và bảo hiểm.</p>
            <p><strong>Mục đích pháp lý</strong></p>
            <p>Chúng tôi có thể sử dụng Dữ Liệu Cá Nhân mà chúng tôi thu thập để điều tra và giải
              quyết khiếu nại hoặc tranh chấp hoặc khi được cho phép hoặc yêu cầu theo pháp luật áp
              dụng. Chúng tôi cũng có thể sử dụng Dữ liệu Cá nhân không có sự đồng thuận, bằng cách
              dựa trên ngoại lệ về Quyền lợi Hợp pháp cho các mục đích như phát hiện và ngăn chặn
              gian lận.</p>
            <p>Chúng tôi cũng có thể sử dụng Dữ Liệu Cá Nhân của bạn khi chúng tôi được yêu cầu,
              được tư vấn, đề nghị, đòi hỏi hoặc thỉnh cầu làm như vậy bởi các cố vấn pháp lý của
              chúng tôi hoặc bất kỳ quy định pháp luật, văn bản dưới luật, thuộc về chính phủ hay
              các cơ quan có thẩm quyền.</p>
            <p>Ví dụ: chúng tôi có thể sử dụng Dữ Liệu Cá Nhân của bạn để:</p>
            <ul>
              <li>Tuân thủ các lệnh của tòa án hoặc các yêu cầu pháp lý, thuộc về chính phủ hoặc quy
                định khác;
              </li>
              <li>Thực thi Điều Khoản Dịch Vụ của chúng tôi hoặc các thỏa thuận khác; và</li>
              <li>Bảo vệ quyền hoặc tài sản của chúng tôi trong trường hợp khiếu nại hoặc tranh
                chấp.
              </li>
            </ul>
            <p>Chúng tôi cũng có thể sử dụng Dữ Liệu Cá Nhân của bạn liên quan đến việc sáp nhập,
              mua lại, liên doanh, bán tài sản công ty, hợp nhất, tái cấu trúc, tài chính, giao dịch
              tài sản kinh doanh hoặc mua lại tất cả hoặc một phần hoạt động kinh doanh của chúng
              tôi bởi một công ty khác.</p>
            <p><strong>Tiếp Thị và Quảng Bá</strong></p>
            <p>Chúng tôi có thể sử dụng Dữ Liệu Cá Nhân của bạn để tiếp thị cho SongYen và các nhà
              tài trợ đối tác SongYen, và các nhà quảng cáo sản phẩm, dịch vụ, sự kiện hoặc chương
              trình khuyến mại. Ví dụ, chúng tôi có thể:</p>
            <ul>
              <li>Gửi cho bạn thông báo, bản tin, cập nhật, thư điện tử, tài liệu quảng cáo, đặc
                quyền, lời chúc mừng lễ hội; và
              </li>
              <li>Thông báo, mời và quản lý sự tham gia của bạn vào các sự kiện hoặc hoạt động của
                chúng tôi;
              </li>
            </ul>
            <p>Chúng tôi có thể liên lạc tiếp thị như vậy với bạn qua bưu điện, cuộc gọi điện thoại,
              dịch vụ tin nhắn ngắn, dịch vụ nhắn tin trực tuyến, thông báo tay và qua email.</p>
            <p>Nếu bạn muốn hủy đăng ký xử lý Dữ Liệu Cá Nhân cho mục đích tiếp thị và khuyến mại,
              vui lòng nhấp vào liên kết hủy đăng ký trong email hoặc tin nhắn có liên quan. Ngoài
              ra, bạn cũng có thể cập nhật tùy chọn của mình trong phần cài đặt Ứng Dụng.</p>
            <h2><strong>III. TIẾT LỘ DỮ LIỆU CÁ NHÂN</strong></h2>
            <p>Chúng tôi cần chia sẻ Dữ Liệu Cá Nhân với các bên khác nhau cho các Mục Đích. Các bên
              này bao gồm:</p>
            <p><strong>Người dùng</strong></p>
            <p>Ví dụ:</p>
            <ul>
              <li>Nếu bạn là đối tác, chúng tôi có thể chia sẻ Dữ liệu Cá nhân của bạn với đối tác
                thương mại và người sử dụng bạn đã chọn, bao gồm tên và hình ảnh; thông tin sản
                phẩm, loại sản phẩm, vị trí và mức đánh giá trung bình.
              </li>
              <li>Nếu bạn sử dụng dịch vụ trò chuyện trong ứng dụng, chúng tôi có thể chia sẻ số
                điện thoại của bạn và tên đăng ký SongYen của bạn với các bên khác vào cuộc trò
                chuyện của bạn.
              </li>
            </ul>
            <p><strong>Với bên thứ ba</strong></p>
            <p>Chúng tôi có thể chia sẻ Dữ liệu Cá nhân của bạn với các bên sau:</p>
            <ul>
              <li>Nếu bạn là khách hàng của đối tác đại lý, chúng tôi có thể chia sẻ vị trí và thông
                tin của bạn với các bên thứ ba khi bạn sử dụng SongYen.
              </li>
              <li>Nếu bạn là đối tác đại lý, chúng tôi có thể dựa trên lợi ích hợp pháp để tiết lộ
                vị trí và thông tin của bạn cho khách hàng nhằm mục đích lựa chọn đại lý gần đây
                nhất.
              </li>
            </ul>
            <p><strong>Với các đối tác SongYen theo yêu cầu của bạn</strong></p>
            <p>Ví dụ: nếu bạn yêu cầu một dịch vụ thông qua đối tác SongYen hoặc sử dụng chương
              trình khuyến mại do đối tác SongYen cung cấp, SongYen có thể chia sẻ Dữ Liệu Cá Nhân
              của bạn với các đối tác đó. Các đối tác của chúng tôi bao gồm các đối tác tích hợp với
              Ứng Dụng của chúng tôi hoặc Ứng Dụng của chúng tôi tích hợp với hệ thống của đối tác,
              đối tác dịch vụ vận chuyển hoặc các đối tác kinh doanh mà SongYen hợp tác để cung cấp
              chương trình khuyến mại, các dịch vụ cạnh tranh hoặc dịch vụ đặc biệt khác.</p>
            <p><strong>Với chủ sở hữu tài khoản SongYen mà bạn có thể sử dụng</strong></p>
            <p>Chúng tôi có thể cung cấp Dữ Liệu Cá Nhân cho các nhà cung cấp, chuyên gia tư vấn,
              đối tác tiếp thị, công ty nghiên cứu và nhà cung cấp dịch vụ hoặc đối tác kinh doanh
              khác. Điều nay bao gồm:</p>
            <ul>
              <li>Bộ phận xử lý thanh toán và bộ phận hỗ trợ;</li>
              <li>Nhà cung cấp lưu trữ đám mây;</li>
              <li>Đối tác tiếp thị và nhà cung cấp nền tảng tiếp thị;</li>
              <li>Nhà cung cấp phân tích dữ liệu;</li>
              <li>Các đối tác nghiên cứu, bao gồm cả những người thực hiện khảo sát hoặc dự án
                nghiên cứu hợp tác với SongYen hoặc thay mặt SongYen;
              </li>
              <li>Các đối tác thương nhân và vận tải;</li>
              <li>Đối tác bảo hiểm và tài chính;</li>
              <li>Các trung gian bên thứ ba có liên quan đến khoản đầu tư được quản lý của các quỹ,
                như bên môi giới, bên quản lý tài sản và bên giám sát;
              </li>
            </ul>
            <p><strong>Với các cố vấn pháp lý của chúng tôi và các cơ quan chính phủ</strong></p>
            <p>Chúng tôi có thể chia sẻ Dữ Liệu Cá Nhân của bạn với các cố vấn pháp lý, các quan
              chức thực thi pháp luật, cơ quan chính phủ và các bên thứ ba khác. Việc này có thể
              được thực hiện để hoàn thành mục đích pháp lý (đã đề cập ở trên), hoặc bất kì trường
              hợp nào sau đây:</p>
            <ul>
              <li>Khi nó là cần thiết để phản ứng với một tình huống khẩn cấp mà đe doạ tính mạng,
                sức khoẻ hoặc sự an toàn của một thực thể; hoặc
              </li>
              <li>Khi nó là cần thiết trong lợi ích công cộng (ví dụ: trong một cuộc khủng hoảng sức
                khỏe công cộng, cho mục đích truy dấu sự tiếp xúc và bảo vệ cộng đồng của chúng ta).
              </li>
            </ul>
            <h2><strong>IV. LƯU GIỮ DỮ LIỆU CÁ NHÂN</strong></h2>
            <p>Chúng tôi lưu giữ Dữ Liệu Cá Nhân của bạn trong khoảng thời gian cần thiết để thực
              hiện các Mục đích được nêu trong Thông báo này trừ khi pháp luật yêu cầu hoặc cho phép
              một khoảng thời gian lưu trữ lâu hơn. Khi Dữ Liệu Cá Nhân của bạn không còn cần thiết
              cho Dịch Vụ hoặc Mục Đích, hoặc chúng tôi không còn có mục đích kinh doanh hoặc pháp
              lý để giữ lại Dữ Liệu Cá Nhân của bạn, chúng tôi sẽ thực hiện các bước để xoá, tiêu
              huỷ, ẩn danh hoặc ngăn chặn việc truy cập hoặc sử dụng Dữ Liệu Cá Nhân cho bất kỳ mục
              đích nào ngoài việc tuân thủ theo Thông báo này, hoặc cho mục đích an toàn, bảo mật,
              phát hiện và phòng chống gian lận, phù hợp với các yêu cầu của luật áp dụng hiện
              hành.</p>
            <h2><strong>V. COOKIES VÀ QUẢNG CÁO TRÊN CÁC NỀN TẢNG BÊN THỨ BA </strong></h2>
            <p>SongYen và các bên thứ ba mà chúng tôi hợp tác, có thể sẽ sử dụng cookies, web
              beacon, web tag, 3web script, các dữ liệu chia sẻ địa phương như HTML5, công cụ định
              danh quảng cáo và các công nghệ tương tự (theo đây được gọi chung là “Cookies”) có
              liên quan đến việc sử dụng các Trang Web và Ứng Dụng của bạn. Tùy thuộc vào loại điện
              thoại và phần mềm điều hành mà bạn đang sử dụng, bạn có thể được cấp tùy chọn để vô
              hiệu hóa việc sử dụng nhận dạng di động của chúng tôi (chẳng hạn như IDFA của Apple).
              Cookies có thể chứa đựng các công cụ định danh độc nhất và trú tại, ngoài các nơi
              khác, máy tính hoặc thiết bị di động của bạn, trong email mà chúng tôi gửi cho bạn, và
              trên các trang web của chúng tôi. Cookies có thể truyền tải Dữ Liệu Cá Nhân về bạn và
              việc sử dụng Dịch Vụ của bạn, chẳng hạn như loại trình duyệt web của bạn, tìm kiếm yêu
              thích, địa chỉ IP, dữ liệu có liên quan đến quảng cáo mà được trình chiếu cho bạn hoặc
              được bạn bấm vào, và ngày giờ sử dụng của bạn.&nbsp; Cookies có thể được lưu trữ trong
              từng phần cụ thể.</p>
            <p>SongYen có thể cho phép các bên thứ ba sử dụng Cookies trên Websites và Ứng Dụng để
              thu thập cùng loại Dữ Liệu Cá Nhân cho cùng mục đích mà SongYen tự làm. Các bên thứ ba
              có thể liên kết Dữ Liệu Cá Nhân họ thu thập với Dữ Liệu Cá Nhân khác mà họ có về bạn
              từ các nguồn khác. Chúng tôi không cần thiết có quyền truy cập hoặc kiểm soát Cookies
              họ sử dụng.</p>
            <p>Ngoài ra, chúng tôi có thể chia sẻ Dữ Liệu Cá Nhân không thể nhận dạng cá nhân với
              các bên thứ ba, chẳng hạn như dữ liệu vị trí, số nhận dạng quảng cáo hoặc hàm mật mã
              của số nhận dạng tài khoản chung (như địa chỉ email), để tạo điều kiện hiển thị quảng
              cáo trên các nền tảng bên thứ ba.</p>
            <p>Vô hiệu hóa việc thu thập cookies trên các trang web của chúng tôi</p>
            <p>Bạn có thể hủy kích hoạt cookies bằng cách điều chỉnh cài đặt trình duyệt internet
              của mình để tắt, chặn hoặc hủy kích hoạt cookies, bằng cách xóa lịch sử duyệt web và
              xóa bộ nhớ cache khỏi trình duyệt internet của bạn.</p>
            <p>Tắt hiển thị Quảng cáo được nhắm mục tiêu thông qua Ứng dụng của chúng tôi</p>
            <p>Bạn có thể giới hạn việc chúng tôi sử dụng dữ liệu cá nhân của bạn để hiển thị các
              quảng cáo có thể liên quan đến bạn, bằng cách điều chỉnh tùy chọn chia sẻ một số Dữ
              liệu cá nhân này thông qua Ứng dụng SongYen của chúng tôi. Bạn vẫn sẽ thấy các quảng
              cáo không nhắm mục tiêu.</p>
            <h2><strong>VI. BẢO VỆ DỮ LIỆU CÁ NHÂN</strong></h2>
            <p>Chúng tôi sẽ thực hiện các biện pháp về mặt tổ chức, kỹ thuật và pháp lý phù hợp để
              bảo vệ dữ liệu cá nhân của bạn. Các biện pháp này có thể bao gồm ngăn chặn tổn thất dữ
              liệu, hay ngăn chặn việc sử dụng hoặc tiếp cận dữ liệu trái phép. Chúng tôi giới hạn
              quyền tiếp cận dữ liệu cá nhân ở nhân viên trên cơ sở cần biết. Chỉ những người được
              ủy quyền mới được phép xử lý dữ liệu cá nhân của bạn và thông tin của bạn sẽ được bảo
              mật.</p>
            <p>Tuy nhiên, lưu ý rằng việc truyền thông tin qua Internet không hoàn toàn được bảo
              mật. Mặc dù chúng tôi sẽ cố gắng hết sức để bảo vệ dữ liệu cá nhân của bạn, chúng tôi
              không thể đảm bảo an toàn dữ liệu của bạn khi truyền qua các phương tiện trực tuyến,
              và do đó chúng tôi không chịu trách nhiệm về rủi ro truyền dữ liệu.</p>
            <h2><strong>VII. QUYỀN CỦA BẠN ĐỐI VỚI DỮ LIỆU CÁ NHÂN</strong></h2>
            <p>Theo luật áp dụng và quy định hiện hành, bạn có thể được quyền:</p>
            <ul>
              <li>Hỏi chúng tôi về việc xử lý Dữ liệu Cá nhân của bạn, bao gồm để được cung cấp một
                bản sao Dữ liệu Cá nhân của bạn;
              </li>
              <li>Yêu cầu chỉnh sửa và/ hoặc (trong một số trường hợp) xóa Dữ liệu Cá nhân của
                bạn;
              </li>
              <li>Trong một số trường hợp, yêu cầu hạn chế xử lý Dữ liệu Cá nhân của bạn hoặc phản
                đối việc xử lý đó;
              </li>
              <li>Rút lại sự đồng thuận của bạn về việc xử lý Dữ liệu Cá nhân của bạn (khi chúng tôi
                đang xử lý Dữ liệu Cá nhân của bạn dựa trên sự đồng thuận của bạn);
              </li>
              <li>Yêu cầu nhận hoặc truyền cho một tổ chức khác, dưới dạng máy có thể đọc được, Dữ
                liệu Cá nhân mà bạn đã cung cấp cho chúng tôi khi chúng tôi đang sử dụng Dữ liệu Cá
                nhân của bạn dựa trên sự đồng thuận hoặc thực hiện một hợp đồng; và
              </li>
              <li>Khiếu nại với cơ quan chức năng giám sát có liên quan nếu quyền riêng tư của bạn
                bị vi phạm, hoặc nếu bạn phải chịu hậu quả của việc xử lý Dữ liệu Cá nhân của bạn
                bất hợp pháp.
              </li>
            </ul>
            <p>Khi bạn được cung cấp tùy chọn chia sẻ Dữ liệu Cá nhân của bạn với chúng tôi, bạn
              luôn có thể chọn không làm như vậy. Nếu chúng tôi đã yêu cầu bạn đồng thuận xử lý và
              sau đó bạn chọn rút lại, chúng tôi sẽ tôn trọng lựa chọn đó theo nghĩa vụ pháp lý của
              chúng tôi.</p>
            <p>Tuy nhiên, chọn không chia sẻ Dữ liệu Cá nhân của bạn với chúng tôi hoặc rút lại sự
              đồng thuận của bạn để chúng tôi sử dụng nó có thể có nghĩa là chúng tôi không thể thực
              hiện các hành động cần thiết để đạt được mục đích xử lý được mô tả trong <strong>Phần
                II (Sử dụng Dữ liệu Cá nhân)</strong> hoặc rằng bạn không thể sử dụng Dịch vụ. Sau
              khi bạn chọn rút lại sự đồng thuận của mình, chúng tôi có thể tiếp tục xử lý Dữ liệu
              cá nhân của bạn trong phạm vi được yêu cầu hoặc theo luật khác hiện hành.</p>
            <p>Nếu bạn muốn đưa ra yêu cầu thực hiện các quyền của mình, bạn có thể liên hệ với
              chúng tôi thông qua chi tiết liên hệ của chúng tôi được nêu trong <strong>Phần IX
                (Cách liên hệ với chúng tôi)</strong> bên dưới.</p>
            <p>Chúng tôi sẽ sàng lọc và xác minh tất cả các yêu cầu trước. Để xác minh thẩm quyền
              thực hiện yêu cầu của bạn, chúng tôi có thể yêu cầu bạn cung cấp thông tin hoặc tài
              liệu hỗ trợ để chứng thực yêu cầu.</p>
            <p>Sau khi xác minh, chúng tôi sẽ xử lý các yêu cầu của bạn trong thời hạn được quy định
              bởi pháp luật.</p>
            <h2><strong>VIII. SỬA ĐỔI VÀ CẬP NHẬT</strong></h2>
            <p>SongYen có thể sửa đổi, cập nhật hoặc điều chỉnh các điều khoản của Chính Sách này
              tại bất cứ thời điểm nào. Các điều chỉnh đó sẽ được thông báo đến bạn thông qua Ứng
              Dụng và/ hoặc các phương tiện thích hợp khác trước thời điểm có hiệu lực ít nhất năm
              (05) ngày làm việc. Phiên bản cập nhật sẽ được đăng tải trên trang web https:// . Bạn
              có trách nhiệm thường xuyên kiểm tra Chính sách Bảo mật trên trang web. Việc tiếp tục
              sử dụng Ứng Dụng, trang web hoặc Dịch Vụ, mua sản phẩm từ SongYen hoặc tiếp tục liên
              lạc hoặc tham gia với SongYen sau khi có các sửa đổi, cập nhật hoặc điều chỉnh Chính
              Sách này, bất kể là bạn đã xem xét hay chưa, sẽ tương đương với việc bạn đã chấp thuận
              và đồng ý đối với những sửa đổi, cập nhật hoặc điều chỉnh đó.</p>
            <h2><strong>IX. CÁCH LIÊN HỆ VỚI CHÚNG TÔI </strong></h2>
            <p>Nếu bạn có bất kỳ thắc mắc nào về Thông báo này hoặc muốn thực hiện quyền của bạn
              được nêu ra trong Thông báo này, vui lòng liên hệ với Nhân viên Bảo vệ Dữ liệu của
              chúng tôi tại:</p>
            <p>Phòng Chăm Sóc Khách Hàng</p>
            <p>CÔNG TY CỔ PHẦN QUỐC TẾ HOÀNG NAM </p>
            <p>Địa chỉ: Số 140 Đường số 14, KDC Him Lam, Phường Tân Hưng, Quận 7, TP. Hồ Chí
              Minh</p>
            <p>Số điện thoại: (028) 7309 6939</p>
            <p>Email: thuonghieuvisuckhoe@gmail.com</p>
          </div>
        </div>
      </div>
    </main>
    <footer id="site-footer">
      <div className="container">
        <div id="footer-wrap">
          <div className="footer-widget">
            <div className="footer-widget-content">
              <p>
                <strong>CÔNG TY CỔ PHẦN QUỐC TẾ HOÀNG NAM</strong><br/>
                Địa chỉ: Số 140 Đường số 14, KDC Him Lam, Phường Tân Hưng, Quận 7, TP. Hồ Chí
                Minh<br/>
                Số điện thoại: (028) 7309 6939<br/>
                Email: thuonghieuvisuckhoe@gmail.com<br/>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>;
}

export default PolicyPage;