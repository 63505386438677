import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, InputAdornment, Switch,
  TextField, Typography
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductCreateRequest } from '../model';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import { isAuthAdmin } from '../../../service/Auth';
import RichTextEditor from '../../../components/RichtextEditor';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { createProduct, getCategory, updateProduct } from '../../../service/Common';
import { enqueueSnackbar } from 'notistack';
import AttachmentArea from '../../../components/AttachmentArea';
import { LocalAttachment } from '../../../service/Common/models';
import { CategoryTypes } from '../../Category/model';
import _ from 'lodash';
import LoadingBackdrop from '../../../components/global/LoadingBackdrop';

interface Props {
  open: boolean;
  detail?: Partial<ProductCreateRequest>;
  onClose: () => void;
  needRetrigger: () => void;
}

const CreateOrUpdateProductDialog = (props: Props) => {
  const authProfile = useSelector(getProfile);
  const isAdmin = isAuthAdmin(authProfile);
  const [currentForm, setCurrentForm] = useState<Partial<ProductCreateRequest>>({});
  const [categoryType, setCategoryType] = useState('');
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const categoryOptions = useMemo(() => {
    if (!categoryType) {
      return [];
    }
    return categoryData.filter((c) => c.type === categoryType);
  }, [categoryType, categoryData]);

  useEffect(() => {
    if (props.detail) {
      setCurrentForm({
        ...props.detail,
        cacheImages: (props.detail.imageDtos ?? []),
        cacheDescription: props.detail.description,
        categoryIds: (props.detail.categories ?? []).map((o) => o.id)
      });
      setCategoryType(_.get(props.detail.categories, '[0].type', ''));
    } else {
      setCurrentForm({});
    }
  }, [props.detail]);

  const handleUpdate = (key: string, value: any) => {
    setCurrentForm((cur) => ({ ...cur, [key]: value }));
  };
  const handleInsertFile = (info: LocalAttachment) => {
    setCurrentForm((cur) => ({
      ...cur,
      imageDtos: [
        ...(cur.imageDtos ?? []),
        { id: info.uuid, url: info.objectURL, blob: info.blob }
      ]
    }))
  };

  const handleRemoveFile = ({ uuid }: Partial<LocalAttachment>) => {
    setCurrentForm((cur) => ({
      ...cur,
      imageDtos: (cur.imageDtos ?? []).filter((img) => img.id !== uuid)
    }))
  }
  const handleClose = () => {
    props.onClose();
    setCurrentForm({});
    setIsDirty(false);
  }
  const isInvalidForm = () => {
    return _.isEmpty(currentForm.name) || !currentForm.price;
  }
  const handleCreateOrUpdate = useCallback(() => {
    setIsDirty(true);
    if (isInvalidForm()) {
      enqueueSnackbar('Thiếu trường thông tin bắt buộc', { variant: 'warning' });
      return;
    }
    setLoading(true);
    if (!currentForm.id) {
      createProduct(currentForm)
      .then(() => {
        enqueueSnackbar('Tạo sản phẩm thành công', { variant: 'success' });
        handleClose();
        props.needRetrigger();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Tạo sản phẩm thất bại', { variant: 'error' });
      }).finally(() => setLoading(false));
    } else {
      updateProduct(currentForm.id, currentForm)
      .then(() => {
        enqueueSnackbar('Cập nhật sản phẩm thành công', { variant: 'success' });
        props.needRetrigger();
        handleClose();
      }).catch(e => {
        console.error(e);
        enqueueSnackbar('Cập nhật sản phẩm thất bại', { variant: 'error' });
      }).finally(() => setLoading(false));
    }
  }, [currentForm]);

  useEffect(() => {
    getCategory({
      size: 1000,
      simpleDto: true
    }).then((res) => {
      setCategoryData(res.results);
    }).catch(console.error)
  }, []);

  return (
      <Dialog
          open={props.open}
          onClose={handleClose}
          maxWidth="xl"
          fullWidth
      >
        <DialogTitle
            id="scroll-dialog-title">
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 700, fontSize: 20, flexGrow: 1 }}>
              {`${props.detail?.id ? 'Cập nhật' : 'Tạo mới'} sản phẩm`}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: 14 }} style={{ paddingTop: 8 }}>
              Sản phẩm nổi bật
            </Typography>
            <Switch
                checked={currentForm.highlight ?? false}
                onChange={(_, val) => handleUpdate('highlight', val)}
                name="gilad"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <LoadingBackdrop open={isLoading}/>
          <Box style={{ margin: 20 }}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('name', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Tên"
                    name="name"
                    value={currentForm.name ?? ''}
                    required
                    error={isDirty && !currentForm.name}
                    helperText={isDirty && !currentForm.name ? 'Không được để trống' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => handleUpdate('shortDes', event?.target?.value ?? '')}
                    variant="outlined"
                    fullWidth
                    id="shortDes"
                    label="Quy cách sản phẩm"
                    name="name"
                    multiline
                    value={currentForm.shortDes || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        options={CategoryTypes}
                        value={CategoryTypes.find((f) => f.value === categoryType) || null}
                        renderInput={(params) => <TextField {...params} label="Loại danh mục"/>}
                        onChange={(_: any, value: any) => {
                          setCategoryType(value?.value ?? '');
                        }}
                    />
                  </Grid>
                  {categoryType && (
                      <Grid item xs={6}>
                        <Autocomplete
                            disablePortal
                            multiple
                            options={categoryOptions ?? []}
                            getOptionLabel={(opt) => opt?.name ?? ''}
                            value={categoryOptions.filter((f) => (currentForm.categories ?? []).find((c) => c.id === f.id)) || []}
                            renderInput={(params) => <TextField {...params} label="Danh mục"/>}
                            onChange={(_: any, value: any) => {
                              handleUpdate('categories', (value ?? []))
                              handleUpdate('categoryIds', (value ?? []).map((c: any) => c.id));
                            }}
                        />
                      </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={4}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleUpdate('price', event?.target?.value ?? '')}
                        variant="outlined"
                        fullWidth
                        id="price"
                        type="number"
                        label="Giá sản phẩm"
                        name="name"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">VNĐ</InputAdornment>
                        }}
                        value={currentForm.price || ''}
                        required
                        error={isDirty && !currentForm.price}
                        helperText={isDirty && !currentForm.price ? 'Không được để trống' : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                        disableFuture
                        format="DD-MM-YYYY"
                        slotProps={{ textField: { fullWidth: true } }}
                        label="Ngày sản xuất"
                        value={currentForm.manufacturingDate ? moment(currentForm.manufacturingDate) : null}
                        onChange={(newValue: any) => handleUpdate('manufacturingDate', newValue?.toDate()?.getTime() ?? 0)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                        slotProps={{ textField: { fullWidth: true } }}
                        format="DD-MM-YYYY"
                        label="Ngày hết hạn"
                        value={currentForm.expiredDate ? moment(currentForm.expiredDate) : null}
                        onChange={(newValue: any) => handleUpdate('expiredDate', newValue?.toDate()?.getTime() ?? 0)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RichTextEditor
                    label="Mô tả sản phẩm"
                    value={currentForm.cacheDescription}
                    onChange={(val) => handleUpdate('description', val.html)}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ fontWeight: '700' }}>Danh sách hình ảnh</Box>
                  </Grid>
                  <Grid item xs={12}>
                    <AttachmentArea
                        capturedImages={currentForm.imageDtos ?? []}
                        insertAttachment={handleInsertFile}
                        removeAttachment={handleRemoveFile}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
              color="warning"
              variant="outlined"
              onClick={handleClose}>
            Đóng
          </Button>
          <Button
              variant="contained"
              onClick={handleCreateOrUpdate}>
            {`${currentForm.id ? 'Cập nhật' : 'Tạo'}`}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default CreateOrUpdateProductDialog;