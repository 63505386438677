import React, { useEffect } from 'react';
import './style.css';

const DeleteAccountPolicyPage = () => {
  useEffect(() => {
    document.title = 'Làm thế nào để tôi xóa, hủy tài khoản SongYen của mình? - SongYen';
  }, []);
  return <div style={{ fontSize: '62.5%' }}>
    <main id="main">
      <div className="section">
        <div className="container">
          <h1 id="post-title">Làm thế nào để tôi xóa, hủy tài khoản SongYen của mình?</h1>
          <div className="post-content">
            <div></div>
            <div>Để yêu cầu xóa/hủy tài khoản <strong>SongYen</strong> của bản thân, vui lòng đăng
              nhập tài khoản <strong>SongYen</strong> trên Ứng dụng <strong>SongYen</strong></div>
            <div></div>
            <div></div>
            <div><strong>Bước 1:</strong> Truy cập <strong>Tài khoản</strong></div>
            <div>
              <img className="aligncenter size-full wp-image-176"
                   src="https://fv5-2.failiem.lv/thumb_show.php?i=tzggxxkf98&download_checksum=081d14e58173aa7bd05ec1802822b122353efc32&download_timestamp=1710128626"
                   alt="" width="453" height="956"/>
            </div>
            <div></div>
            <div><strong>Bước 2:</strong> Chọn <strong>&#8220;Xóa tài khoản</strong>&#8220;</div>
            <div><img decoding="async" className="aligncenter size-full wp-image-182"
                      src="https://fv5-3.failiem.lv/thumb_show.php?i=vn9w6k2vwm&download_checksum=63451ad93e3710d383d11c09563f10a0969d931b&download_timestamp=1710128951"
                      alt="" width="453" height="956" sizes="(max-width: 453px) 100vw, 453px"/>
            </div>
            <div></div>
            <div><strong>Bước 3:</strong> Chọn <strong>&#8220;Đồng ý</strong>&#8220;</div>
            <div><img decoding="async" className="aligncenter size-full wp-image-183"
                      src="https://fv5-3.failiem.lv/thumb_show.php?i=mvm59vmpeq&download_checksum=7b69954453e4f74b5202ce2e34af93fd7c09ce6c&download_timestamp=1710128972"
                      alt="" width="440" height="956" sizes="(max-width: 440px) 100vw, 440px"/>
            </div>
            <div></div>
            <div></div>
            <div><strong>Lưu ý:</strong></div>
            <ul>
              <li>Việc xóa/hủy tài khoản SongYen là vĩnh viễn và không thể khôi phục. Đồng nghĩa bạn
                không thể đăng nhập hoặc xem lại các thông báo trong ứng dụng sau khi tài khoản đã
                bị xóa.
              </li>
              <li>Sau khi bạn đã hủy tài khoản SongYen hiện tại, SongYen có quyền từ chối những yêu
                cầu thiết lập tài khoản mới của bạn trong tương lai.
              </li>
              <li>SongYen không giải quyết bất kì yêu cầu nào liên quan đến việc mua bán cho tài
                khoản đã bị xóa.
              </li>
              <li>Toàn bộ điểm thưởng đã tích lũy (nếu có) trong tài khoản của bạn sẽ bị mất.</li>
              <li>Tất cả yêu cầu trợ giúp của bạn đang được SongYen xử lý sẽ bị dừng hỗ trợ sau khi
                tài khoản bị xóa.
              </li>
              <li>Sau khi xóa tài khoản thành công, bạn không thể đăng nhập và sử dụng được ứng dụng
                SongYen bằng tài khoản đã bị xóa.
              </li>
              <li>SongYen có quyền từ chối yêu cầu tạo hoặc mở lại khoản trong tương lai.</li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <footer id="site-footer">
      <div className="container">
        <div id="footer-wrap">
          <div className="footer-widget">
            <div className="footer-widget-content">
              <p>
                <strong>CÔNG TY CỔ PHẦN QUỐC TẾ HOÀNG NAM</strong><br/>
                Địa chỉ: Số 140 Đường số 14, KDC Him Lam, Phường Tân Hưng, Quận 7, TP. Hồ Chí
                Minh<br/>
                Số điện thoại: (028) 7309 6939<br/>
                Email: thuonghieuvisuckhoe@gmail.com<br/>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>;
}

export default DeleteAccountPolicyPage;