import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Tooltip } from '@mui/material';
import { GrCheckboxSelected } from 'react-icons/gr';
import ConfirmDialog from './ConfirmDialog';
// interface ActionCellProps {
//   params:
// }
const ActionCell = (params: GridRenderCellParams, onClick?: (val: any) => void) => {
  const user = params.row;
  return (
      <div style={{ display: 'inline-flex' }}>
        <IconButton onClick={() => onClick && onClick({ ...user, actionType: 'DELETE' })}>
          <DeleteForeverIcon color="error"/>
        </IconButton>
        {!user.status && user.userType === 'AGENCY' && (
            <Tooltip title="Kích hoạt người dùng">
              <IconButton onClick={() => onClick && onClick({ ...user, actionType: 'ACTIVATE' })}>
                <GrCheckboxSelected color="success"/>
              </IconButton>
            </Tooltip>

        )}
      </div>
  );
}

export default ActionCell;