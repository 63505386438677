import Config from '../../configs/app-config';
import { request } from '../AppClient';
import { clearAuth } from '../../store/auth';
import store from '../../store';
import _ from 'lodash';

const context: any = {};

export type TokenData = {
  id: string;
  fullName: string;
  username: string;
  email: string;
  roles: string[];
};

export type AuthResult = {
  data?: TokenData;
  authenticated: boolean;
};

export async function init(): Promise<AuthResult> {
  const token = localStorage.getItem('access_token');
  if (!token) {
    return Promise.resolve({
      authenticated: false
    });
  }

  return refreshToken()
    .then((res) => {
      console.log(res);
      return {
        authenticated: true,
        data: res
      };
    })
    .catch((err) => {
      tryClearTimer();
      console.log(err);
      logout();
      return Promise.reject('token invalid');
    });
}

function getTokenData(response: any): TokenData | undefined {
  try {
    return response.user;
  } catch (err) {
    console.log(err);
  }

  return undefined;
}

export function login(username: string, password: string): Promise<AuthResult> {
  return request({
    method: 'POST',
    headers: {

    },
    data: {
      password: password,
      userName: username
    },
    url: `${Config.apiUrl}/api/v1/auth/authenticate`
  })
    .then((resp) => {
      console.log('response', resp);
      localStorage.setItem('access_token', resp.token);
      localStorage.setItem('refresh_token', resp.refreshToken);
      localStorage.setItem(
        'access_info',
        JSON.stringify({
          ...resp,
          issueAt: new Date().getTime()
        })
      );

      autoRefreshToken();

      return {
        authenticated: true,
        data: resp.user
      };
    })
    .catch((err) => {
      console.log(err);
      alert('Tên đăng nhập hoặc mật khẩu không đúng.\nVui lòng kiểm tra lại.');
      return Promise.resolve({
        authenticated: false
      });
    });
}

export function logout(): Promise<void> {
  tryClearTimer();

  localStorage.removeItem('access_token');
  localStorage.removeItem('access_info');

  return Promise.resolve();
}

export async function getToken(): Promise<string | null> {
  return Promise.resolve(localStorage.getItem('access_token'));
}

function tryClearTimer() {
  try {
    if (context.timer) {
      clearInterval(context.timer);
    }
  } catch (err) {
    console.log(err);
  }
}

function autoRefreshToken() {
  tryClearTimer();

  context.timer = setInterval(() => {
    refreshToken()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        tryClearTimer();
        console.log(err);
        alert('Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại');
        logout().then(() => store.dispatch(clearAuth({})));
      });
  }, 600000);
  // }, 5000);
}

async function refreshToken(): Promise<TokenData | undefined> {
  const tokenInfo = getTokenInfo();
  if (!tokenInfo) {
    return Promise.resolve(undefined);
  }

  return request({
    method: 'POST',
    data: {
      refreshToken: tokenInfo.refreshToken
    },
    url: `${Config.apiUrl}/api/v1/auth/refresh`
  }).then((resp) => {
    localStorage.setItem('access_token', resp.token);
    localStorage.setItem(
      'access_info',
      JSON.stringify({
        ...resp,
        issueAt: new Date().getTime()
      })
    );
    return getTokenData(resp);
  });
}

function getTokenInfo(): any {
  const tokenInfoStr = localStorage.getItem('access_info');

  if (!tokenInfoStr) {
    return null;
  }

  try {
    return JSON.parse(tokenInfoStr);
  } catch (err) {
    return null;
  }
}

export const isAuthAdmin = (authProfile: any): boolean => {
  return _.get(authProfile, 'userType', '') === 'ADMIN';
}
