import config from '../../configs/app-config';
import { get, post, postForm, put, putForm, sendDelete } from '../AppClient';
import axios from 'axios';
import { PreSignedUrlDownload } from '../../utils/types';
import queryString from 'query-string';
import { Attachment } from './models';

export type FileInfo = {
  id: string;
  uploadUrl: string;
  group: string;
  name: string;
  type: string;
  checksum: string;
  desc: string;
  size: string;
  status: string;
  createdAt: string;
};

export type AppConfig = {
  id: string;
  type: string;
  code: string;
  name: string;
  fullName: string;
  codeName: string;
  parentCode: string;
}

export async function createPreSignedUrl(
    system: string,
    group: string,
    type: string,
    size: string,
    fileName: string,
    file: any,
    upload: any
): Promise<FileInfo> {
  const result = await post(`${config.apiUrl}/api/v1/resource/${system}`, {
    group,
    name: fileName,
    type: type,
    desc: ''
  }).then((fileResource) => {
    return processUploadFileS3(fileResource, upload);
  });

  return result;
}

async function processUploadFileS3(fileResource: any, upload: any): Promise<FileInfo> {
  try {
    const url = config.formatS3Url(fileResource.uploadUrl);
    const axiosResponse = await axios.put(url, upload, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
    console.log('Response:', axiosResponse.data);
    return Promise.resolve(fileResource);
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
}

export async function getResourceUrls(system: string, resourceId: string) {
  return get(`${config.apiUrl}/api/v1/resource/${system}/${resourceId}/url`).then(
      (response: PreSignedUrlDownload) => {
        return response;
      }
  );
}

export async function getUsers(filter?: any) {
  return get(
      queryString.stringifyUrl({
        url: `${config.apiUrl}/api/v1/users`,
        query: {
          page: (filter?.page ?? 1) - 1,
          size: filter?.size ?? 20,
          keyword: filter?.keyword,
          userType: filter?.userType
        }
      }, {
        skipNull: true,
        skipEmptyString: true
      })
  )
}

export async function getProducts(filter?: any) {
  return get(
      queryString.stringifyUrl({
        url: `${config.apiUrl}/api/v1/products`,
        query: {
          page: (filter?.page ?? 1) - 1,
          size: 20,
          keyword: filter?.keyword,
          categoryType: filter?.categoryType,
          categoryIds: filter?.category ? [filter?.category] : []
        }
      }, {
        skipNull: true,
        skipEmptyString: true
      })
  )
}

export const createUser = async (form: any) => {
  return post(`${config.apiUrl}/api/v1/users/register`, form);
}

export const updateUser = async (userId: string, form: any) => {
  const formData = new FormData();
  formData.append('user', JSON.stringify(form));
  return putForm(
      `${config.apiUrl}/api/v1/users/${userId}`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export const createProduct = async (form: any) => {
  const formData = new FormData();
  const formJson = { ...form };
  delete formJson.imageDtos;
  formData.append('product', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('images', f.blob);
  })
  return postForm(
      `${config.apiUrl}/api/v1/products`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export const updateProduct = async (productId: string, form: any) => {
  const formData = new FormData();
  const formJson = { ...form };
  formJson.deletedImages = form.cacheImages
  .filter((c: Attachment) => !form.imageDtos.find((f: Attachment) => f.id === c.id))
  .map((c: Attachment) => c.id);
  delete formJson.imageDtos;
  formData.append('product', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('images', f.blob);
  });

  return putForm(
      `${config.apiUrl}/api/v1/products/${productId}`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export async function getPromotions(filter?: any) {
  return get(
      queryString.stringifyUrl({
        url: `${config.apiUrl}/api/v1/promotions`,
        query: {
          page: (filter?.page ?? 1) - 1,
          size: 20,
          keyword: filter?.keyword,
          type: filter?.type
        }
      }, {
        skipNull: true,
        skipEmptyString: true
      })
  )
}

export const createPromotion = async (form: any) => {
  const formData = new FormData();
  const formJson = { ...form };
  delete formJson.imageDtos;
  formData.append('promotion', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('images', f.blob);
  })
  return postForm(
      `${config.apiUrl}/api/v1/promotions`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export const updatePromotion = async (promotionId: string, form: any) => {
  const formData = new FormData();
  const formJson = { ...form };
  formJson.deletedImages = form.cacheImages
  .filter((c: Attachment) => !form.imageDtos.find((f: Attachment) => f.id === c.id))
  .map((c: Attachment) => c.id);
  delete formJson.imageDtos;
  formData.append('promotion', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('images', f.blob);
  });

  return putForm(
      `${config.apiUrl}/api/v1/promotions/${promotionId}`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export async function getBanners(filter?: any) {
  return get(
      queryString.stringifyUrl({
        url: `${config.apiUrl}/api/v1/banners`,
        query: {
          page: (filter?.page ?? 1) - 1,
          size: 20,
          keyword: filter?.keyword,
          type: filter?.type
        }
      }, {
        skipNull: true,
        skipEmptyString: true
      })
  )
}

export const createBanner = async (form: any) => {
  const formData = new FormData();
  const formJson = { ...form, type: form.type ?? 'HORIZONTAL' };
  delete formJson.imageDtos;
  formData.append('banner', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('image', f.blob);
  });
  return postForm(
      `${config.apiUrl}/api/v1/banners`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export const updateBanner = async (bannerId: string, form: any) => {
  const formData = new FormData();
  const formJson = { ...form };
  formData.append('banner', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('image', f.blob);
  });

  return putForm(
      `${config.apiUrl}/api/v1/banners/${bannerId}`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}


export async function getCategory(filter?: any) {
  return get(
      queryString.stringifyUrl({
        url: `${config.apiUrl}/api/v1/categories`,
        query: {
          page: (filter?.page ?? 1) - 1,
          size: (filter?.page ?? 20),
          keyword: filter?.keyword,
          type: filter?.type
        }
      }, {
        skipNull: true,
        skipEmptyString: true
      })
  )
}

export const createCategory = async (form: any) => {
  const formData = new FormData();
  const formJson = { ...form };
  delete formJson.imageDtos;
  formData.append('category', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('images', f.blob);
  })
  return postForm(
      `${config.apiUrl}/api/v1/categories`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export const updateCategory = async (CategoryId: string, form: any) => {
  const formData = new FormData();
  const formJson = { ...form };
  formJson.deletedImages = form.cacheImages
  .filter((c: Attachment) => !form.imageDtos.find((f: Attachment) => f.id === c.id))
  .map((c: Attachment) => c.id);
  delete formJson.imageDtos;
  formData.append('category', JSON.stringify(formJson));
  (form.imageDtos ?? []).forEach((f: Attachment) => {
    f.blob && formData.append('images', f.blob);
  });

  return putForm(
      `${config.apiUrl}/api/v1/categories/${CategoryId}`,
      formData,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
  );
}

export const deleteUser = async (userId: string) => {
  return sendDelete(`${config.apiUrl}/api/v1/users/${userId}`);
}
export const deleteProduct = async (productId: string) => {
  return sendDelete(`${config.apiUrl}/api/v1/products/${productId}`);
}
export const deleteBanner = async (bannerId: string) => {
  return sendDelete(`${config.apiUrl}/api/v1/banners/${bannerId}`);
}
export const deletePromotion = async (promotionId: string) => {
  return sendDelete(`${config.apiUrl}/api/v1/promotions/${promotionId}`);
}

export const deleteCategory = async (categoryId: string) => {
  return sendDelete(`${config.apiUrl}/api/v1/categories/${categoryId}`);
}
export const activateUser = async (userId: string) => {
  return put(`${config.apiUrl}/api/v1/users/${userId}/activate`, {});
}

export const getConfigByTypeAndCode = async (type: string, code: string): Promise<AppConfig> => {
  return get(`${config.apiUrl}/api/v1/config/${type}/${code}`);
}

export const getChildConfigsByTypeAndCode = async (type: string, code: string): Promise<AppConfig[]> => {
  return get(`${config.apiUrl}/api/v1/config/${type}/${code}/list`);
}

export const requestSendEmail = async (username: string) => {
  return put(`${config.apiUrl}/api/v1/auth/password/${username}`, {});
}

export const resetPassword = async (payload: any) => {
  return post(`${config.apiUrl}/api/v1/auth/reset-password`, payload);
}