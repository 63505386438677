import React, { useCallback, useState } from 'react';
import { Box, Pagination, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/PageHeader';
import SearchForm from './components/SearchForm';
import useUsersColumn from './components/useUsersColumn';
import CreateOrUpdateUserDialog from './components/CreateOrUpdateUserDialog';
import ConfirmDialog from './components/ConfirmDialog';
import { activateUser, deleteUser } from '../../service/Common';
import { enqueueSnackbar } from 'notistack';

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filter, setFilter] = useState<{ [key: string]: any }>({ trigger: false });
  const [data, setData] = useState<any[]>([]);
  const [selectUser, setSelectedUser] = useState<any>();
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const columns = useUsersColumn(((filter.page ?? 1) - 1) * (filter.size ?? 20), setSelectedUser);
  const handleDeleteUser = useCallback(() => {
    if (!selectUser) {
      return;
    }
    deleteUser(selectUser.id)
    .then(() => {
      setFilter((cur) => ({ ...cur, trigger: !cur.trigger }));
      enqueueSnackbar('Xoá người dùng thành công', { variant: 'success' });
    })
    .catch(e => {
      console.error(e);
      enqueueSnackbar('Xoá người dùng thất bại', { variant: 'error' });
    }).finally(() => {
      setSelectedUser(undefined);
    });
  }, [selectUser, filter]);

  const handleActivateUser = useCallback(() => {
    if (!selectUser) {
      return;
    }
    activateUser(selectUser.id)
    .then(() => {
      setFilter((cur) => ({ ...cur, trigger: !cur.trigger }));
      enqueueSnackbar('Kích hoạt người dùng thành công', { variant: 'success' });
    })
    .catch(e => {
      console.error(e);
      enqueueSnackbar('Kích hoạt người dùng thất bại', { variant: 'error' });
    }).finally(() => {
      setSelectedUser(undefined);
    });
  }, [selectUser, filter]);
  return (
      <Box style={{
        backgroundColor: colors.primary[400],
        padding: '0 15px',
        height: '-webkit-fill-available'
      }}>
        <Header
            title="Quản lý người dùng"
            subtitle=""
        />
        <Box
            m="20px 0 0 0"
            height="calc(100vh - 230px)"
            sx={{
              '& .MuiDataGrid-root': {
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
              },
              '& .no-border-bottom': {
                borderBottom: 'none !important',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: colors.blueAccent[700],
                borderBottom: 'none',
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: colors.primary[400],
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: colors.blueAccent[700],
              },
              '& .MuiCheckbox-root': {
                color: `${colors.greenAccent[200]} !important`,
              },
              '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                color: `${colors.grey[100]} !important`,
              }
            }}
        >
          <SearchForm
              filter={filter}
              setFilter={setFilter}
              setData={setData}
              onCreate={() => setOpenDetailDialog(true)}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Pagination
                page={(filter.page ?? 1)}
                onChange={(_, page) => setFilter((cur) => ({ ...cur, page }))}
                count={Math.ceil((filter.total ?? 0) / (filter.size ?? 20))}
            />
          </Box>
          <DataGrid
              sx={{ '& .MuiDataGrid-columnSeparator': { visibility: 'hidden !important', } }}
              hideFooter
              rows={data}
              columns={columns}
              getRowId={(d) => d.id}
              onRowDoubleClick={(params) => setSelectedUser({ ...params.row, actionType: 'VIEW' })}
          />
        </Box>
        <CreateOrUpdateUserDialog
            open={Boolean(selectUser?.actionType === 'VIEW' || openDetailDialog)}
            onClose={() => {
              setSelectedUser(undefined);
              setOpenDetailDialog(false);
            }}
            detail={selectUser}
            needRetrigger={() => setFilter((cur) => ({ ...cur, trigger: !cur.trigger }))}
        />
        <ConfirmDialog
            open={selectUser?.actionType === 'DELETE'}
            onClose={() => setSelectedUser(undefined)}
            onConfirm={handleDeleteUser}
            title="Xác nhận xóa người dùng?"
            confirmLabel="Xóa"
            confirmVariant="error"
        />
        <ConfirmDialog
            open={selectUser?.actionType === 'ACTIVATE'}
            onClose={() => setSelectedUser(undefined)}
            onConfirm={handleActivateUser}
            title="Xác nhận kích hoạt người dùng?"
            confirmLabel="Kích hoạt"
            confirmVariant="success"
        />
      </Box>
  );
};

export default Home;
