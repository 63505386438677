import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal, Typography
} from '@mui/material';
import React from 'react';

interface Props {
  open: boolean;
  title: string;
  body?: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDeleteDialog = (props: Props) => {
  return (
      <Dialog
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography>{props.title}</Typography>
        </DialogTitle>
        {props.body && (
            <DialogContent>
              {props.body}
            </DialogContent>
        )}
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="error" onClick={props.onConfirm}>Xóa</Button>
        </DialogActions>
      </Dialog>
  );
}

export default ConfirmDeleteDialog;