import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import AuthApp from './AuthApp';
import { Provider } from 'react-redux';
import store from './store';
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@mui/material/styles';
import NotistackWrapper from './components/global/NotistackWrapper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'


const theme = unstable_createMuiStrictModeTheme();
const dateFormat = 'DD-MM-YYYY';
const dateFormats: Partial<Record<string, string>> = { dateFormat };

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Suspense
              fallback={
                <CircularProgress/>
              }>
            <LocalizationProvider dateAdapter={AdapterMoment} dateFormats={dateFormats}>
              <NotistackWrapper>
                <AuthApp/>
              </NotistackWrapper>
            </LocalizationProvider>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
