import { useEffect, useState } from 'react';
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import { isAuthAdmin } from '../../../service/Auth';
import ActionCell from './ActionCell';
import { BannerType } from '../model';

const useBannerColumn = (offset = 0, setSelectedUser?: (val: any) => void) => {
  const [column, setColumn] = useState<GridEnrichedColDef[]>([]);
  const authProfile = useSelector(getProfile);
  const isAdmin = isAuthAdmin(authProfile);
  useEffect(() => {
    const defaultColumns: GridEnrichedColDef[] = [
      {
        field: 'STT',
        width: 100,
        sortable: false,
        renderCell: (index) => index.api.getRowIndexRelativeToVisibleRows(index.row.id) + offset + 1
      },
      {
        field: 'name',
        headerName: 'Tên',
        flex: 1,
        sortable: false
      },
      {
        field: 'link',
        headerName: 'Link',
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'description',
        headerName: 'Mô tả',
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'type',
        headerName: 'Loại banner',
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        valueGetter: (params) => BannerType.find((c) => c.value === params.row.type)?.label ?? ''
      }
    ]
    if (isAdmin) {
      defaultColumns.push(
          {
            field: 'actionCell',
            headerName: 'Action',
            flex: 1,
            sortable: false,
            renderCell: (params) => (ActionCell(params, setSelectedUser))
          });
    }
    setColumn([...defaultColumns]);
  }, [offset]);
  return column;
}

export default useBannerColumn;
