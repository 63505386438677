export interface TaskTotal {
  TODO: number;
  IN_PROGRESS: number;
  DONE: number;
}

export const USER_TYPE_OPTIONS = [
  { label: 'Tất cả', value: null },
  { label: 'Khách hàng', value: 'CUSTOMER' },
  { label: 'Đại lý', value: 'AGENCY' },
  { label: 'Nhân viên', value: 'STAFF' },
  { label: 'Admin', value: 'ADMIN' }
];

export const USER_TYPE_DICT = {
  CUSTOMER: USER_TYPE_OPTIONS[1],
  AGENCY: USER_TYPE_OPTIONS[2],
  STAFF: USER_TYPE_OPTIONS[3],
  ADMIN: USER_TYPE_OPTIONS[4],
}

export type Address = {
  province: string,
  district: string,
  ward: string,
  provinceCode: string,
  districtCode: string,
  wardCode: string,
  address: string
}

export type User = {
  id: string;
  name: string;
  userName: string;
  phoneNumber: string;
  email: string;
  dob: number;
  userType: string;
  address: Address;
  avatarUrl: string;
  createdAt: number;
  referralCount: number;
  referralUrl: string;
  status: string;
}

export type UserCreateRequest = User & {
  password: string;
}
