import { useEffect, useState } from 'react';
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { USER_TYPE_OPTIONS } from '../model';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../store/auth/selector';
import { isAuthAdmin } from '../../../service/Auth';
import ActionCell from './ActionCell';

const useUsersColumn = (offset = 0, setSelectedUser?: (val: any) => void) => {
  const [column, setColumn] = useState<GridEnrichedColDef[]>([]);
  const authProfile = useSelector(getProfile);
  const isAdmin = isAuthAdmin(authProfile);
  useEffect(() => {
    const defaultColumns: GridEnrichedColDef[] = [
      {
        field: 'STT',
        width: 100,
        sortable: false,
        renderCell: (index) => index.api.getRowIndexRelativeToVisibleRows(index.row.id) + offset + 1
      },
      {
        field: 'name',
        headerName: 'Tên',
        flex: 1,
        sortable: false
      },
      {
        field: 'userName',
        headerName: 'Username',
        flex: 1,
        sortable: false
      },
      {
        field: 'phoneNumber',
        headerName: 'Số điện thoại',
        sortable: false,
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1
      },
      {
        field: 'userType',
        headerName: 'Loại người dùng',
        sortable: false,
        valueGetter: (params) => {
          const userType = params.row?.userType ?? '';
          return USER_TYPE_OPTIONS.find((u) => u.value === userType)?.label ?? userType;
        }
      }
    ]
    if (isAdmin) {
      defaultColumns.push(
          {
            field: 'actionCell',
            headerName: 'Action',
            flex: 1,
            sortable: false,
            renderCell: (params) => (ActionCell(params, setSelectedUser))
          });
    }
    setColumn([...defaultColumns]);
  }, [offset]);
  return column;
}

export default useUsersColumn;
