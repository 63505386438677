import { Attachment } from '../../service/Common/models';

export type Category = {
  id: string,
  name: string,
  description: string,
  type: string,
}

export type Promotion = {
  id: string,
  name: string,
  description: string,
  partner: string,
  type: string,
  discountAmount: number,
  discountPercent: number,
  startDate: number,
  endDate: number,
  images: string[],
  imageDtos: Attachment[],
  highlight: boolean
}

export type PromotionCreateRequest = Promotion & {
  categories: string[],
  cacheImages: Attachment[],
  cacheDescription: string
}

export const PromotionType = [
  {label: 'Tin tức', value: 'NEWS'},
  {label: 'Voucher', value: 'VOUCHER'},
]