import { Attachment } from "../../service/Common/models"


export type Category = {
  id: string,
  name: string,
  description: string,
  type: string,
  images: string[],
  imageDtos: Attachment[],
  highlight: boolean
}


export type CategoryCreateRequest = Category & {
  categories: string[],
  cacheImages: Attachment[],
  cacheDescription: string
}

export const CategoryTypes = [
  { label: 'Sản phẩm Yến', value: 'DEFAULT' },
  { label: 'Song Yến làm đẹp', value: 'COSMETICS' },
  { label: 'Song Yến sức khỏe', value: 'HEALTH' }
]