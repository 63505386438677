export const DEFAULT_PAGE_SIZE = 10;
export const FIRST_PAGE = 1;
export const MAX_PAGE_SIZE = 999;
export const COMMA = ',';
export const DEFAULT_DOCUMENT_CATEGORY = 'assets';
export const DEFAULT_DOCUMENT_CODE = 'assets';
export const DEFAULT_FIELD_CODE = 'files';
export const DEFAULT_SYSTEM_RESOURCE = 'edu';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const FAKE_ID = 'fake_id';

export const CATEGORY_DICTIONARY = 'dictionary';
export const CATEGORY_CURRICULUM = 'curriculum';
export const CATEGORY_DOCUMENT = 'assets';
export const CATEGORY_PROFILE = 'profile';
export const CATEGORY_SYLLABUS = 'syllabus';

export const RESOURCE_SYSTEM_CMS = 'vlu-cms';
export const RESOURCE_GROUP_CURRICULUM = 'curriculum';
export const RESOURCE_GROUP_CURRICULUM_TEMPLATE = 'curriculum-template';
export const RESOURCE_GROUP_CURRICULUM_DIAGRAM = 'curriculum-diagram';
export const CONFIG_TRUONG_PHONG_PDT_ID = 'keycloak.group.pdt.truong-phong';
export const CONFIG_NHAN_VIEN_PHONG_PDT_ID = 'keycloak.group.pdt.nhan-vien';

export const DOCX_FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
