const config = {
  policyEnforcerDisabled: false,
  // apiUrl: 'http://127.0.0.1:8080',
  apiUrl: 'https://api.songyen.org',
  getAuthRequestHeaders(): any {
    return {};
  },
  formatS3Url(url: string): string {
    return url;
  },
  appVersion: '1.0.0'
};

export default config;
