import React, { useCallback, useState } from 'react';
import { Box, Pagination, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/PageHeader';
import SearchForm from './components/SearchForm';
import useProductColumn from './components/useProductColumn';
import CreateOrUpdateProductDialog from './components/CreateOrUpdateProductDialog';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog';
import { deleteProduct } from '../../service/Common';
import { enqueueSnackbar } from 'notistack';

const ProductManagement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filter, setFilter] = useState<{ [key: string]: any }>({ trigger: false });
  const [data, setData] = useState<any[]>([]);
  const [selectProduct, setSelectedProduct] = useState<any>();
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const columns = useProductColumn(((filter.page ?? 1) - 1) * (filter.size ?? 20), setSelectedProduct);

  const handleDeleteProduct = useCallback(() => {
    if (!selectProduct) {
      return;
    }
    deleteProduct(selectProduct.id)
    .then(() => {
      setFilter((cur) => ({ ...cur, trigger: !cur.trigger }));
      enqueueSnackbar('Xoá sản phẩm thành công', { variant: 'success' });
    })
    .catch(e => {
      console.error(e);
      enqueueSnackbar('Xoá sản phẩm thất bại', { variant: 'error' });
    }).finally(() => {
      setSelectedProduct(undefined);
    });
  }, [selectProduct, filter]);

  return (
      <Box style={{
        backgroundColor: colors.primary[400],
        padding: '0 15px',
        height: '-webkit-fill-available'
      }}>
        <Header
            title="Quản lý sản phẩm"
            subtitle=""
        />
        <Box
            m="20px 0 0 0"
            height="calc(100vh - 230px)"
            sx={{
              '& .MuiDataGrid-root': {
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
              },
              '& .no-border-bottom': {
                borderBottom: 'none !important',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: colors.blueAccent[700],
                borderBottom: 'none',
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: colors.primary[400],
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: colors.blueAccent[700],
              },
              '& .MuiCheckbox-root': {
                color: `${colors.greenAccent[200]} !important`,
              },
              '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                color: `${colors.grey[100]} !important`,
              },
              '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
            }}
        >
          <SearchForm
              filter={filter}
              setFilter={setFilter}
              setData={setData}
              onCreate={() => setOpenDetailDialog(true)}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Pagination
                page={(filter.page ?? 1)}
                onChange={(_, page) => setFilter((cur) => ({ ...cur, page }))}
                count={Math.ceil((filter.total ?? 0) / (filter.size ?? 20))}
            />
          </Box>
          <DataGrid
              sx={{ '& .MuiDataGrid-columnSeparator': { visibility: 'hidden !important', } }}
              hideFooter
              rows={data}
              columns={columns}
              getRowId={(d) => d.id}
              onRowDoubleClick={(params) => setSelectedProduct({
                ...params.row,
                actionType: 'VIEW'
              })}
          />
        </Box>
        <CreateOrUpdateProductDialog
            open={Boolean(selectProduct?.actionType === 'VIEW' || openDetailDialog)}
            onClose={() => {
              setSelectedProduct(undefined);
              setOpenDetailDialog(false);
              // setFilter((cur) =>  ({ ...cur, trigger: !cur.trigger }))
            }}
            detail={selectProduct}
            needRetrigger={() => setFilter((cur) => ({ ...cur, trigger: !cur.trigger }))}
        />
        <ConfirmDeleteDialog
            open={selectProduct?.actionType === 'DELETE'}
            onClose={() => setSelectedProduct(undefined)}
            onConfirm={handleDeleteProduct}
            title="Xác nhận xóa sản phẩm?"
        />
      </Box>
  );
};

export default ProductManagement;
