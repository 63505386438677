import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CrumbPath,
  INITIAL_UI_STATE,
  NotificationFiler,
  SnackbarMessage,
  UiState
} from './ui.state';
import { GetNotificationResponse } from '../../service/Common/models';

const SLICE_NAME = 'ui-common';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_UI_STATE,
  reducers: {
    addSnackbarAction: (state: UiState, action: PayloadAction<SnackbarMessage>) => {
      const newList = [...state.messages, action.payload];
      if (newList.length > 5) {
        newList.shift();
      }
      state.messages = newList;
    },
    removeSnackbarAction: (state: UiState, action: PayloadAction<{ id: string }>) => {
      state.messages = state.messages.filter((n) => n.id !== action.payload.id);
    },
    setCurrentParentPathAction: (state: UiState, action: PayloadAction<CrumbPath>) => {
      state.currentParentPath = action.payload;
    },
    setNotificationAction: (state: UiState, action: PayloadAction<GetNotificationResponse>) => {
      const response = action.payload;
      state.notification = {
        data: response.result,
        filter: { ...state.notification.filter, total: response.total}
      }
    },
    markNotificationAsReadAction: (state: UiState, action: PayloadAction<number>) => {
      const dataId = action.payload;
      const notification = state.notification.data.find((n) => n.dataId === dataId);
      if (notification) {
        notification.readAt = new Date();
      }
    },
    updateFilterNotificationAction: (state: UiState, action: PayloadAction<Partial<NotificationFiler>>) => {
      const filter = state.notification.filter;
      state.notification.filter = { ...filter, ...action.payload };
    },
    updateShowSideBarAction: (state: UiState, action: PayloadAction<boolean>) => {
      state.layoutState.showSideBar = action.payload;
    }
  }
});

export const {
  addSnackbarAction,
  removeSnackbarAction,
  setCurrentParentPathAction,
  setNotificationAction,
  markNotificationAsReadAction,
  updateFilterNotificationAction,
  updateShowSideBarAction
} = slice.actions;

export default slice.reducer;
