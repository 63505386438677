import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { Box, Grid, useTheme } from '@mui/material';
import './styles.css';

export interface EditorContentChanged {
  html: string
}

export interface EditorProps {
  label?: string;
  value?: string;
  onChange?: (changes: EditorContentChanged) => void;
}

const TOOLBAR_OPTIONS = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  ['link', 'image', 'video'],

  [{ 'header': 1 }, { 'header': 2 }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean']
];

export default function Editor(props: EditorProps) {
  const [value, setValue] = useState<string>(props.value ?? '');
  const reactQuillRef = useRef<ReactQuill>(null);
  const theme = useTheme();

  useEffect(() => {
    setValue(props.value ?? '');
  }, [props.value]);

  const handleOnBlur = (range: any, source: any, quill: any) => {
    if (props.onChange) {
      props.onChange({
        html: value
      });
    }
  };
  const onChange = (content: string) => {
    setValue(content);
    if (props.onChange) {
      props.onChange({
        html: content
      });
    }
  };

  return (
      <Grid container>
        {props.label && (
            <Grid item xs={12}>
              <Box sx={{ fontWeight: '700' }}>{props.label}</Box>
            </Grid>
        )}
        <Grid item xs={12}>
          <ReactQuill
              className={theme.palette.mode === 'dark' ? 'custom-ql-formats-dark' : 'custom-ql-formats-light'}
              ref={reactQuillRef}
              theme="snow"
              modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }
              }}
              value={value}
              onChange={onChange}
              onBlur={handleOnBlur}
          />
        </Grid>
      </Grid>
  );
}
